import React, { useContext, useEffect, useState } from "react";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";
import LeadsGeneratorTable from "../../components/LeadsGenerator/LeadsGeneratorTable";
import "./LeadsManager.css";
import { AuthContext } from "../../components/context/AuthContext";
import axios from "axios";
import UploadCompaniesSection from "../../components/LeadsGenerator/UploadCompaniesSection";
import CreateLead from "../../components/LeadsGenerator/CreateLead";
import SeniorLeadsTable from "../../components/LeadsGenerator/senior/SeniorLeadsTable";
import JuniorLeadsTable from "../../components/LeadsGenerator/junior/JuniorLeadsTable";
import { baseUrl } from "../../api/Api";
import NewLeadsGeneratorTable from "../../components/LeadsGenerator/NewLeadsGeneratorTable";

const LeadsManager = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLeadsTableActive, setIsLeadsTableActive] = useState(true);
  const [isCreateLeadActive, setIsCreateLeadActive] = useState(false);
  const [isSeniorLeadsTableActive, setIsSeniorLeadsTableActive] =
    useState(false);
  const [isJuniorLeadsTableActive, setIsJuniorLeadsTableActive] =
    useState(false);
  const [isUploadActive, setIsUploadActive] = useState(false);
  const [showNames, setShowNames] = useState(null);
  const [timezones, setTimezones] = useState(null);
  const [seniorLeadsData, setSeniorLeadsData] = useState(null);
  const [juniorLeadsData, setJuniorLeadsData] = useState(null);

  const { token, role } = useContext(AuthContext);

  const fetchShowNames = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/shows/all-shows/names`);
      setShowNames(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error Fetching data", error);
    }
  };

  const fetchTimezones = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/shows/all-timezones`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTimezones(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error Fetching data", error);
    }
  };

  const fetchSeniorLeadsData = async () => {
    try {
      console.log("Fetching staging data...");
      const response = await axios.get(
        `${baseUrl}/api/companies/show-tracker`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSeniorLeadsData(response.data.data);
      console.log("Staging data fetched:", response.data);
    } catch (error) {
      console.error("Error fetching staging data:", error);
    }
  };

  const fetchJuniorLeadsData = async () => {
    try {
      console.log("Fetching staging data...");
      const response = await axios.get(
        `${baseUrl}/api/companies/junior-leads`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setJuniorLeadsData(response.data);
      console.log("Staging data fetched:", response.data);
    } catch (error) {
      console.error("Error fetching staging data:", error);
    }
  };

  useEffect(() => {
    if (isLeadsTableActive) {
      fetchShowNames();
      // Optionally, you could trigger a fetch for leads here if needed in the future.
    }
    if (isCreateLeadActive) {
      fetchShowNames();
      fetchTimezones();
    }

    if (isSeniorLeadsTableActive) {
      fetchSeniorLeadsData();
    }
    if (isJuniorLeadsTableActive) {
      fetchJuniorLeadsData();
    }
  }, [
    isLeadsTableActive,
    isCreateLeadActive,
    isSeniorLeadsTableActive,
    isJuniorLeadsTableActive,
  ]);

  const toggleSidebar = () => setIsOpen(!isOpen);

  const showPricingTable = () => {
    setIsLeadsTableActive(true);
    setIsCreateLeadActive(false);
    setIsUploadActive(false);
    setIsSeniorLeadsTableActive(false);
    setIsJuniorLeadsTableActive(false);
  };

  const showCreateContent = () => {
    setIsCreateLeadActive(true);
    setIsLeadsTableActive(false);
    setIsUploadActive(false);
    setIsSeniorLeadsTableActive(false);
    setIsJuniorLeadsTableActive(false);
  };

  const showSeniorLeadsTable = () => {
    setIsCreateLeadActive(false);
    setIsLeadsTableActive(false);
    setIsUploadActive(false);
    setIsSeniorLeadsTableActive(true);
    setIsJuniorLeadsTableActive(false);
  };

  const showJuniorLeadsTable = () => {
    setIsCreateLeadActive(false);
    setIsLeadsTableActive(false);
    setIsUploadActive(false);
    setIsSeniorLeadsTableActive(false);
    setIsJuniorLeadsTableActive(true);
  };

  const showUploadControllers = () => {
    setIsUploadActive(true);
    setIsCreateLeadActive(false);
    setIsLeadsTableActive(false);
    setIsSeniorLeadsTableActive(false);
    setIsJuniorLeadsTableActive(false);
  };

  return (
    <div className="pricing-manager-container">
      <div className="main-header-info">
        <h2 className="main-header-h2">Leads Generator View</h2>
      </div>
      <div className="main-pricing-contain">
        <div className="sidebar-container">
          {isOpen ? (
            <div className="menu-bar-open">
              <MdOutlineClose className="menu-icon" onClick={toggleSidebar} />
              <button onClick={showPricingTable} className="sidebar-btn">
                Incomplete Leads Table
              </button>
              {role === "leads senior" && (
                <button onClick={showCreateContent} className="sidebar-btn">
                  Create Lead
                </button>
              )}
              {role === "leads senior" && (
                <button onClick={showSeniorLeadsTable} className="sidebar-btn">
                  Juniors Counts
                </button>
              )}
              {/* {role === "leads junior" && (
                <button onClick={showJuniorLeadsTable}>
                  Junior Leads Table
                </button>
              )} */}
              <button onClick={showUploadControllers} className="sidebar-btn">
                Upload Bulk Leads
              </button>
            </div>
          ) : (
            <div className="menu-bar-close">
              <HiOutlineMenuAlt2
                className="menu-icon"
                onClick={toggleSidebar}
              />
            </div>
          )}
        </div>
        {isLeadsTableActive && (
          <div className="table-container">
            <h2 className="table-title">Incomplete Leads Table</h2>
            <NewLeadsGeneratorTable
              showNames={showNames}
              // fetchLeads={fetchLeads}  // Remove this line as it's no longer needed
            />
          </div>
        )}
        {isCreateLeadActive && (
          <div className="table-container">
            <h2 className="table-title">Create Lead</h2>
            <CreateLead showNames={showNames} timezones={timezones} />
          </div>
        )}
        {isUploadActive && (
          <div className="table-container">
            <h2 className="table-title">Upload Bulk Data</h2>
            <UploadCompaniesSection showNames={showNames} />
          </div>
        )}
        {isSeniorLeadsTableActive && (
          <SeniorLeadsTable
            data={seniorLeadsData}
            fetchSeniorLeadsData={fetchSeniorLeadsData}
          />
        )}
        {/* {isJuniorLeadsTableActive && (
          <JuniorLeadsTable
            data={juniorLeadsData}
            fetchJuniorLeadsData={fetchJuniorLeadsData}
          />
        )} */}
      </div>
    </div>
  );
};

export default LeadsManager;
