import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import LiveRequestCard from "./LiveRequestCard";
import styles from "./SalesSupportPage.module.css";
import { Button } from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import { baseUrl } from "../../api/Api";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
} from "@mui/material";

const EmailTeamHistory = () => {
  const [emailRequests, setEmailRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [loading, setLoading] = useState(true);
  const { token } = useContext(AuthContext);
  const [selectedRowId, setSelectedRowId] = useState(null);
    // States for search inputs
    const [companySearch, setCompanySearch] = useState("");
    const [emailSearch, setEmailSearch] = useState("");
  

  const hotelData = emailRequests.flatMap((item) =>
    item.pricingData.flatMap((hotel) => hotel.Hotels.map((h) => h))
  );
  console.log("hotelData", hotelData);
  console.log("emailRequests", emailRequests);

  useEffect(() => {
    const fetchEmailRequests = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/email-team/history-email-requests`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setEmailRequests(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching live requests:", error);
      }
    };

    fetchEmailRequests();
  }, []);

  const handleCardClick = (request) => {
    setSelectedRequest(request);
    console.log("request", request);
  };

  const updateEmailFeedback = async (feedback) => {
    if (!selectedRequest) return;

    try {
      const response = await axios.post(
        `${baseUrl}/api/email-team/update-email-feedback`,
        {
          showId: selectedRequest.ShowId,
          leadId: selectedRequest.CompanyId,
          emailFeedback: feedback,
          requestId: selectedRequest._id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Feedback sent:", response.data);

      // Update the emailRequests state to reflect the feedback change
      setEmailRequests((prevRequests) =>
        prevRequests.map((req) =>
          req.CompanyId === selectedRequest.CompanyId
            ? {
                ...req,
                companyData: { ...req.companyData, emailFeedback: feedback },
              }
            : req
        )
      );
    } catch (error) {
      console.error("Error sending feedback:", error);
    }
  };

  const handleRowClick = (id) => {
    setSelectedRowId(id);
  };

  // Filtered emailRequests based on search inputs
  const filteredRequests = emailRequests.filter((request) => {
    const matchesCompany =
      request.companyData.Lead.toLowerCase().includes(companySearch.toLowerCase());
    const matchesEmail =
      request.companyData.Email.toLowerCase().includes(emailSearch.toLowerCase());
    return matchesCompany && matchesEmail;
  });


  if (loading) {
    return (
      <div className="spinner-container">
        Loading...
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h1>Email Team Page</h1>
      <div className={styles.content}>
        <ul className={styles.requestList}>
          <div className="search-email-req-container" style={{ display: "flex", flexDirection: "column", gap: 5 }}>
            <div className="assign-lead-filter-contain">
            <input
                type="text"
                id="company-search"
                placeholder="Search by Company Name"
                style={{
                  padding: "8px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                  marginLeft: "10px",
                  width: "280px",
                }}
                value={companySearch} // Bind state
                onChange={(e) => setCompanySearch(e.target.value)} // Update state
              />
            </div>
            <div className="assign-lead-filter-contain">
            <input
                type="text"
                id="email-search"
                placeholder="Search by Email"
                style={{
                  padding: "8px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                  marginLeft: "10px",
                  width: "280px",
                }}
                value={emailSearch} // Bind state
                onChange={(e) => setEmailSearch(e.target.value)} // Update state
              />
            </div>
          </div>
          <h2>Email Requests</h2>
          {filteredRequests.length === 0 ? (
          <p>No requests match your search criteria.</p>
        ) : (
          filteredRequests.slice().reverse().map((request) => (
            <li
              key={request._id}
              onClick={() => handleCardClick(request)}
              style={{
                background:
                  request.companyData.emailFeedback === "Sent"
                    ? "green"
                    : request.companyData.emailFeedback === "Error Sent"
                    ? "red"
                    : "transparent",
                color: "white",
                borderRadius: "5px",
              }}
            >
              <LiveRequestCard message={{ data: request }} />
            </li>
          ))
        )}
        </ul>
        <div className={styles.requestDetails}>
          <h2>Request Details : </h2>
          {selectedRequest && (
            <div>
              <p>
                <strong>Show Name:</strong> {selectedRequest?.showData?.Show}
              </p>
              <p>
                <strong>Company:</strong> {selectedRequest?.companyData?.Lead}
              </p>
              <p>
                <strong>Email:</strong> {selectedRequest?.companyData?.Email}
              </p>

              <p>
                <strong>Request:</strong> {selectedRequest?.Request}
              </p>
              <p>
                <strong>Agent Name:</strong> {selectedRequest?.agentName}
              </p>
              <p>
                <strong>Requested Data:</strong>
                {selectedRequest?.RequestedData}
              </p>
              <p>
                <strong>State:</strong> {selectedRequest?.State}
              </p>
              <Button
                variant="outlined"
                style={{
                  marginTop: "1rem",
                  marginRight: "0.5rem",
                  backgroundImage: "linear-gradient(90deg, #32CD32, #228B22)",
                  border: "none",
                }}
                onClick={() => updateEmailFeedback("Sent")}
              >
                Send Correct Email
              </Button>

              <Button
                variant="outlined"
                style={{
                  marginTop: "1rem",
                  marginLeft: "0.5rem",
                  backgroundImage: "linear-gradient(90deg, #FF6347, #B22222)",
                  border: "none",
                }}
                onClick={() => updateEmailFeedback("Error Sent")}
              >
                Send Error Email
              </Button>
            </div>
          )}
          {selectedRequest && (
            <div className={styles.emailPriceTable__container}>
              <TableContainer
                component={Paper}
                sx={{
                  overflow: "auto",
                  maxHeight: "600px",
                  // minWidth: "800px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                  maxWidth: "100%",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      {[
                        "Hotel Name",
                        "Address",
                        "Stars",
                        "Selling Price",
                        "Boarding Type",
                        "Distance",
                        "Room Type",
                        "Free Cancellation Until",
                        "Online Price",
                      ].map((header, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            background: "#8b5a5a",
                            color: "white",
                            // whiteSpace: "nowrap",
                            // wordBreak: "keep-all",
                          }}
                        >
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {hotelData.map((hotel, index) => (
                      <TableRow
                        key={hotel._id}
                        onClick={() => handleRowClick(hotel._id)}
                        sx={{
                          background: selectedRowId === hotel._id && "#A9A9A9",
                          cursor: "pointer",
                          color:
                            selectedRowId === hotel._id ? "#fff" : "inherit",
                          "&:hover": {
                            background: "#A9A9A9",
                            color: '"#fff',
                          },
                        }}
                      >
                        <TableCell
                          sx={
                            {
                              // whiteSpace: "nowrap",
                              // wordBreak: "keep-all",
                            }
                          }
                        >
                          {hotel["Hotel Name"]}
                        </TableCell>
                        <TableCell>{hotel.Address}</TableCell>
                        <TableCell>{hotel.Stars}</TableCell>
                        <TableCell>{hotel.SellingPrice}</TableCell>
                        <TableCell>{hotel["Boarding Type"]}</TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            wordBreak: "keep-all",
                          }}
                        >
                          {hotel.Distance}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            wordBreak: "keep-all",
                          }}
                        >
                          {hotel["Room Type"]}
                        </TableCell>
                        <TableCell>
                          {hotel["Free Cancellation Until"]}
                        </TableCell>
                        <TableCell>{hotel["Online Price"]}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default EmailTeamHistory;

























// import React, { useState, useEffect, useContext } from "react";
// import axios from "axios";
// import LiveRequestCard from "./LiveRequestCard";
// import styles from "./SalesSupportPage.module.css";
// import { Button } from "@mui/material";
// import { AuthContext } from "../context/AuthContext";
// import { baseUrl } from "../../api/Api";
// import {
//   Table,
//   TableHead,
//   TableBody,
//   TableCell,
//   TableRow,
//   TableContainer,
//   Paper,
// } from "@mui/material";

// const EmailTeamHistory = () => {
//   const [emailRequests, setEmailRequests] = useState([]);
//   const [selectedRequest, setSelectedRequest] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const { token } = useContext(AuthContext);
//   const [selectedRowId, setSelectedRowId] = useState(null);

//   console.log("emailRequests", emailRequests);
//   const hotelData = emailRequests.flatMap((item) =>
//     item.pricingData.flatMap((hotel) => hotel.Hotels.map((h) => h))
//   );
//   console.log("hotelData", hotelData);
//   console.log("emailRequests", emailRequests);

//   useEffect(() => {
//     const fetchEmailRequests = async () => {
//       try {
//         const response = await axios.get(
//           `${baseUrl}/api/email-team/history-email-requests`,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//         setEmailRequests(response.data);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching live requests:", error);
//       }
//     };

//     fetchEmailRequests();
//   }, []);

//   const handleCardClick = (request) => {
//     setSelectedRequest(request);
//     console.log("request", request);
//   };

//   const updateEmailFeedback = async (feedback) => {
//     if (!selectedRequest) return;

//     try {
//       const response = await axios.post(
//         `${baseUrl}/api/email-team/update-email-feedback`,
//         {
//           showId: selectedRequest.ShowId,
//           leadId: selectedRequest.CompanyId,
//           emailFeedback: feedback,
//           requestId: selectedRequest._id,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       console.log("Feedback sent:", response.data);

//       // Update the emailRequests state to reflect the feedback change
//       setEmailRequests((prevRequests) =>
//         prevRequests.map((req) =>
//           req.CompanyId === selectedRequest.CompanyId
//             ? {
//                 ...req,
//                 companyData: { ...req.companyData, emailFeedback: feedback },
//               }
//             : req
//         )
//       );
//     } catch (error) {
//       console.error("Error sending feedback:", error);
//     }
//   };

//   const handleRowClick = (id) => {
//     setSelectedRowId(id);
//   };

//   if (loading) {
//     return (
//       <div className="spinner-container">
//         Loading...
//         <div className="spinner"></div>
//       </div>
//     );
//   }

//   return (
//     <div className={styles.container}>
//       <h1>Email Team Page</h1>
//       <div className={styles.content}>
//         <ul className={styles.requestList}>
//           <div className="search-email-req-container" style={{ display: "flex", flexDirection: "column", gap: 5 }}>
//             <div className="assign-lead-filter-contain">
//               <input
//                 type="text"
//                 id="company-search"
//                 placeholder="Search by Company Name"
//                 style={{
//                   padding: "8px",
//                   borderRadius: "4px",
//                   border: "1px solid #ccc",
//                   marginLeft: "10px",
//                   width: "280px",
//                 }}
//               />
//             </div>
//             <div className="assign-lead-filter-contain">
//               <input
//                 type="text"
//                 id="company-search"
//                 placeholder="Search by Email"
//                 style={{
//                   padding: "8px",
//                   borderRadius: "4px",
//                   border: "1px solid #ccc",
//                   marginLeft: "10px",
//                   width: "280px",
//                 }}
//               />
//             </div>
//           </div>
//           <h2>Email Requests</h2>
//           {emailRequests
//             .slice()
//             .reverse()
//             .map((request, index) => (
//               <li
//                 key={request._id}
//                 onClick={() => handleCardClick(request)}
//                 style={{
//                   background:
//                     request.companyData.emailFeedback === "Sent"
//                       ? "green"
//                       : request.companyData.emailFeedback === "Error Sent"
//                       ? "red"
//                       : "transparent",
//                   color: "white",
//                   borderRadius: "5px",
//                 }}
//               >
//                 <LiveRequestCard message={{ data: request }} />
//               </li>
//             ))}
//         </ul>
//         <div className={styles.requestDetails}>
//           <h2>Request Details : </h2>
//           {selectedRequest && (
//             <div>
//               <p>
//                 <strong>Show Name:</strong> {selectedRequest?.showData?.Show}
//               </p>
//               <p>
//                 <strong>Company:</strong> {selectedRequest?.companyData?.Lead}
//               </p>
//               <p>
//                 <strong>Email:</strong> {selectedRequest?.companyData?.Email}
//               </p>

//               <p>
//                 <strong>Request:</strong> {selectedRequest?.Request}
//               </p>
//               <p>
//                 <strong>Agent Name:</strong> {selectedRequest?.agentName}
//               </p>
//               <p>
//                 <strong>Requested Data:</strong>
//                 {selectedRequest?.RequestedData}
//               </p>
//               <p>
//                 <strong>State:</strong> {selectedRequest?.State}
//               </p>
//               <Button
//                 variant="outlined"
//                 style={{
//                   marginTop: "1rem",
//                   marginRight: "0.5rem",
//                   backgroundImage: "linear-gradient(90deg, #32CD32, #228B22)",
//                   border: "none",
//                 }}
//                 onClick={() => updateEmailFeedback("Sent")}
//               >
//                 Send Correct Email
//               </Button>

//               <Button
//                 variant="outlined"
//                 style={{
//                   marginTop: "1rem",
//                   marginLeft: "0.5rem",
//                   backgroundImage: "linear-gradient(90deg, #FF6347, #B22222)",
//                   border: "none",
//                 }}
//                 onClick={() => updateEmailFeedback("Error Sent")}
//               >
//                 Send Error Email
//               </Button>
//             </div>
//           )}
//           {selectedRequest && (
//             <div className={styles.emailPriceTable__container}>
//               <TableContainer
//                 component={Paper}
//                 sx={{
//                   overflow: "auto",
//                   maxHeight: "600px",
//                   // minWidth: "800px",
//                   borderRadius: "8px",
//                   boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
//                   maxWidth: "100%",
//                 }}
//               >
//                 <Table>
//                   <TableHead>
//                     <TableRow>
//                       {[
//                         "Hotel Name",
//                         "Address",
//                         "Stars",
//                         "Selling Price",
//                         "Boarding Type",
//                         "Distance",
//                         "Room Type",
//                         "Free Cancellation Until",
//                         "Online Price",
//                       ].map((header, index) => (
//                         <TableCell
//                           key={index}
//                           sx={{
//                             background: "#8b5a5a",
//                             color: "white",
//                             // whiteSpace: "nowrap",
//                             // wordBreak: "keep-all",
//                           }}
//                         >
//                           {header}
//                         </TableCell>
//                       ))}
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     {hotelData.map((hotel, index) => (
//                       <TableRow
//                         key={hotel._id}
//                         onClick={() => handleRowClick(hotel._id)}
//                         sx={{
//                           background: selectedRowId === hotel._id && "#A9A9A9",
//                           cursor: "pointer",
//                           color:
//                             selectedRowId === hotel._id ? "#fff" : "inherit",
//                           "&:hover": {
//                             background: "#A9A9A9",
//                             color: '"#fff',
//                           },
//                         }}
//                       >
//                         <TableCell
//                           sx={
//                             {
//                               // whiteSpace: "nowrap",
//                               // wordBreak: "keep-all",
//                             }
//                           }
//                         >
//                           {hotel["Hotel Name"]}
//                         </TableCell>
//                         <TableCell>{hotel.Address}</TableCell>
//                         <TableCell>{hotel.Stars}</TableCell>
//                         <TableCell>{hotel.SellingPrice}</TableCell>
//                         <TableCell>{hotel["Boarding Type"]}</TableCell>
//                         <TableCell
//                           sx={{
//                             whiteSpace: "nowrap",
//                             wordBreak: "keep-all",
//                           }}
//                         >
//                           {hotel.Distance}
//                         </TableCell>
//                         <TableCell
//                           sx={{
//                             whiteSpace: "nowrap",
//                             wordBreak: "keep-all",
//                           }}
//                         >
//                           {hotel["Room Type"]}
//                         </TableCell>
//                         <TableCell>
//                           {hotel["Free Cancellation Until"]}
//                         </TableCell>
//                         <TableCell>{hotel["Online Price"]}</TableCell>
//                       </TableRow>
//                     ))}
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };
// export default EmailTeamHistory;