import React from "react";
import RequestTimeZone from "../RequestTimeZone/RequestTimeZone";
import RequestEdition from "../RequestTimeZone/RequestEdition";

const NewLeads = ({
  selectOptionsTimezone,
  selectedTimeZone,
  setSelectedTimeZone,
  selectOptionsEdition,
  selectedEdition,
  fetchShows,
  leadsCount,
}) => {
  return (
    <div style={{ display: "flex", alignItems:"center", background:"#fff"}}>
      <RequestTimeZone
        selectOptionsTimezone={selectOptionsTimezone}
        selectedTimeZone={selectedTimeZone}
        setSelectedTimeZone={setSelectedTimeZone}
      />
      <button
        onClick={fetchShows}
        disabled={leadsCount > 0 || !selectedTimeZone}
        className="fetch-data-button"
      >
        <p>        Get More Leads
</p>
      </button>
    </div>
  );
};

export default NewLeads;
