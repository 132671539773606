import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const LiveRequestCard = ({ message }) => {
  return (
    <Card variant="outlined" sx={{ width: "90%", marginBottom: "1rem" }}>
      <CardContent>
        <Typography
          sx={{ fontSize: 14, fontWeight: "bold" }}
          color="text.secondary"
          gutterBottom
        >
          Request: {message.data.Request}
        </Typography>
        <Typography variant="h6" component="div" sx={{ fontSize: 12 }}>
          <span style={{ fontWeight: "bold", fontSize: "14px" }}>
            Show Name:
          </span>{" "}
          {message.data.showData?.Show || message.data.showName}
        </Typography>
        <Typography variant="h6" component="div" sx={{ fontSize: 12 }}>
          <span style={{ fontWeight: "bold", fontSize: "14px" }}>
            Company Name:
          </span>{" "}
          {message.data.companyData?.Lead || message.data.Lead}
        </Typography>
        <Typography sx={{ fontSize: 12 }} color="text.secondary">
          <span style={{ fontWeight: "bold", fontSize: "14px" }}>
            Requested Data:
          </span>{" "}
          {message.data.RequestedData}
        </Typography>
        <Typography variant="body2" sx={{ fontSize: 12 }}>
          <span style={{ fontWeight: "bold", fontSize: "14px" }}>State:</span>{" "}
          {message.data.State}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          style={{
            background: "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
            color: "white",
          }}
        >
          View Details
        </Button>
      </CardActions>
    </Card>
  );
};

export default LiveRequestCard;
