import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";
import PricingTable from "../../components/pricingTeam/PricingTable";
import UploadControllersSection from "../../components/uploadControllers/UploadControllersSection";
import "./PricingManager.css";
import { AuthContext } from "../../components/context/AuthContext";
import JuniorPricingTable from "../../components/pricingTeam/pricingJunior/JuniorPricingTable";
import { baseUrl } from "../../api/Api";
import PricingEmailRequest from "../../components/PricingEmailRequest/PricingEmailRequest";

const PricingManager = () => {
  const { token, role } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [activeTable, setActiveTable] = useState(null);
  const [data, setData] = useState(null);
  const [rawPricing, setRawPricing] = useState(null);

  // Fetch regular pricing data
  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/pricing/all-pricings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Fetch junior staging pricing data
  const fetchManuallyGeneratedRawPricing = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/pricing/manually-generated-pricings`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRawPricing(response.data.data);
    } catch (error) {
      console.error("Error fetching junior staging data:", error);
    }
  };

  useEffect(() => {
    if (activeTable === "pricing") {
      fetchData();
    } else if (activeTable === "raw pricing") {
      fetchManuallyGeneratedRawPricing();
    }
  }, [activeTable]);

  const toggleSidebar = () => setIsOpen(!isOpen);

  const handleShowTable = (table) => {
    setActiveTable(table);
  };

  return (
    <div className="pricing-manager-container">
      <div className="main-header-info">
        <h2 className="main-header-h2">Pricing Team View</h2>
      </div>
      <div className="main-pricing-contain">
        <div className="sidebar-container">
          {isOpen ? (
            <div className="menu-bar-open">
              <MdOutlineClose className="menu-icon" onClick={toggleSidebar} />
              {role !== "pricing junior" && (
                <>
                  <button
                    onClick={() => handleShowTable("pricing")}
                    className="sidebar-btn"
                  >
                    Pricing Table
                  </button>
                  <button
                    onClick={() => handleShowTable("email")}
                    className="sidebar-btn"
                  >
                    Email Request
                  </button>
                </>
              )}
              {role === "pricing senior" && (
                <button
                  onClick={() => handleShowTable("raw pricing")}
                  className="sidebar-btn"
                >
                  Create Raw Pricing
                </button>
              )}
              <button
                onClick={() => handleShowTable("upload")}
                className="sidebar-btn"
              >
                Upload Bulk Data
              </button>
            </div>
          ) : (
            <div className="menu-bar-close">
              <HiOutlineMenuAlt2
                className="menu-icon"
                onClick={toggleSidebar}
              />
            </div>
          )}
        </div>

        {/* Conditional rendering of components */}
        <div className="table-container">
          {activeTable === "pricing" && role !== "pricing junior" && (
            <>
              <h2 className="table-title">Pricing Table</h2>
              <PricingTable data={data} fetchData={fetchData} />
            </>
          )}

          {activeTable === "email" && (
            <>
              <PricingEmailRequest token={token} />
            </>
          )}

          {activeTable === "raw pricing" && (
            <>
              <h2 className="table-title">Create Raw Pricing</h2>
              <JuniorPricingTable
                data={rawPricing}
                fetchData={fetchManuallyGeneratedRawPricing}
                role={role}
              />
            </>
          )}

          {activeTable === "upload" && (
            <>
              <h2 className="table-title">Upload Bulk Data</h2>
              <UploadControllersSection />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PricingManager;

// import React, { useContext, useEffect, useState } from "react";
// import axios from "axios";
// import { HiOutlineMenuAlt2 } from "react-icons/hi";
// import { MdOutlineClose } from "react-icons/md";
// import PricingTable from "../../components/pricingTeam/PricingTable";
// import UploadControllersSection from "../../components/uploadControllers/UploadControllersSection";
// import "./PricingManager.css";
// import { AuthContext } from "../../components/context/AuthContext";
// import JuniorPricingTable from "../../components/pricingTeam/pricingJunior/JuniorPricingTable";
// import { baseUrl } from "../../api/Api";
// import PricingEmailRequest from "../../components/PricingEmailRequest/PricingEmailRequest";

// const PricingManager = () => {
//   const { token, role } = useContext(AuthContext);
//   const [isOpen, setIsOpen] = useState(false);
//   const [isPricingTableActive, setIsPricingTableActive] = useState(false);
//   const [isJuniorStagingTableActive, setIsJuniorStagingTableActive] = useState(false);
//   const [isUploadActive, setIsUploadActive] = useState(false);
//   const [data, setData] = useState(null);
//   const [juniorStagingData, setJuniorStagingData] = useState(null);
//   const [isEmailRequestActive, setIsEmailRequestActive] = useState(false);

//   // Fetch regular pricing data
//   const fetchData = async () => {
//     try {
//       console.log("Fetching data...");
//       const response = await axios.get(`${baseUrl}/api/pricing/all-pricings`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       setData(response.data);
//       console.log("Data fetched:", response.data);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   // Fetch staging pricing data
//   const fetchjuniorStagingData = async () => {
//     try {
//       console.log("Fetching staging data...");
//       const response = await axios.get(`${baseUrl}/api/pricing/all-pricings`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       setJuniorStagingData(response.data);
//       console.log("Staging data fetched:", response.data);
//     } catch (error) {
//       console.error("Error fetching staging data:", error);
//     }
//   };

//   useEffect(() => {
//     if (isPricingTableActive) {
//       fetchData();
//     }
//     if (isJuniorStagingTableActive) {
//       fetchjuniorStagingData();
//     }
//   }, [isPricingTableActive, isJuniorStagingTableActive]);

//   const toggleSidebar = () => setIsOpen(!isOpen);

//   const showPricingTable = () => {
//     setIsPricingTableActive(true);
//     setIsJuniorStagingTableActive(false);
//     setIsUploadActive(false);
//     setIsEmailRequestActive(false);
//   };

//   const showEmailRequest = () => {
//     setIsEmailRequestActive(true);
//     setIsPricingTableActive(false);
//     setIsUploadActive(false);
//     setIsJuniorStagingTableActive(false);
//   };

//   const showJuniorStagingContent = () => {
//     setIsJuniorStagingTableActive(true);
//     setIsPricingTableActive(false);
//     setIsUploadActive(false);
//     setIsEmailRequestActive(false);
//   };

//   const showUploadControllers = () => {
//     setIsUploadActive(true);
//     setIsPricingTableActive(false);
//     setIsJuniorStagingTableActive(false);
//     setIsEmailRequestActive(false);
//   };

//   return (
//     <div className="pricing-manager-container">
//       <div className="main-header-info">
//         <h2 className="main-header-h2">Pricing Team View</h2>
//       </div>
//       <div className="main-pricing-contain">
//         <div className="sidebar-container">
//           {isOpen ? (
//             <div className="menu-bar-open">
//               <MdOutlineClose className="menu-icon" onClick={toggleSidebar} />
//               {role !== "pricing junior" && (
//                 <button onClick={showPricingTable} className="sidebar-btn">
//                   Pricing Table
//                 </button>
//               )}

//               {role !== "pricing junior" && (
//                 <button onClick={showEmailRequest} className="sidebar-btn">
//                   Email Request
//                 </button>
//               )}

//               {role === "pricing junior" && (
//                 <button
//                   onClick={showJuniorStagingContent}
//                   className="sidebar-btn"
//                 >
//                   Stage Pricing
//                 </button>
//               )}

//               <button onClick={showUploadControllers} className="sidebar-btn">
//                 Upload Bulk Data
//               </button>
//             </div>
//           ) : (
//             <div className="menu-bar-close">
//               <HiOutlineMenuAlt2
//                 className="menu-icon"
//                 onClick={toggleSidebar}
//               />
//             </div>
//           )}
//         </div>

//         {/* Conditional rendering of components */}
//         <div className="table-container">
//           {!isUploadActive  && !isEmailRequestActive && (role !== "pricing junior" || isPricingTableActive) && (
//             <>
//               <h2 className="table-title">Pricing Table</h2>
//               <PricingTable data={data} fetchData={fetchData} />
//             </>
//           )}

//           {!isUploadActive && !isPricingTableActive && (role !== "pricing junior" || isEmailRequestActive) && (
//             <>
//               <PricingEmailRequest />
//             </>
//           )}

//           {!isUploadActive && (role === "pricing junior" || isJuniorStagingTableActive) && (
//             <>
//               <h2 className="table-title">Staging Price Table</h2>
//               <JuniorPricingTable
//                 data={juniorStagingData}
//                 fetchData={fetchjuniorStagingData}
//                 role={role}
//               />
//             </>
//           )}

//           {isUploadActive && (
//             <>
//               <h2 className="table-title">Upload Bulk Data</h2>
//               <UploadControllersSection />
//             </>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PricingManager;
