import {useEffect, useState } from "react";

const columnsOfTable = [
  { field: "Lead", headerName: "Lead" },
  { field: "ShowName", headerName: "Show Name" },
  { field: "Phone", headerName: "Phone" },
  { field: "Timezone", headerName: "Timezone" },
  { field: "Status", headerName: "Agent Status" },
  { field: "closerStatus", headerName: "Closer Status" },
  { field: "agentName", headerName: "Agent Name" },
  { field: "answerBtn", headerName: "" },
  { field: "emailBtn", headerName: "" },
  { field: "requestBtn", headerName: "" },
  { field: "liveRequestFeedback", headerName: "" },
];

const AssignedLeadsTable = ({ assignedLeads, handlePopUpOpen }) => {
  const [statusOptions, setStatusOptions] = useState([]);
  const [timezoneOptions, setTimezoneOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedTimezone, setSelectedTimezone] = useState("");
  const [searchCompanyName, setSearchCompanyName] = useState(""); 
  const [filteredLeads, setFilteredLeads] = useState([]);

  useEffect(() => {
    const statuses = assignedLeads.flatMap((item) =>
      item.leads.map((lead) => lead.Status)
    );
    const timezones = assignedLeads.flatMap((item) =>
      item.leads.map((lead) => lead.Timezone)
    );

    setStatusOptions([...new Set(statuses)]);
    setTimezoneOptions([...new Set(timezones)]);
  }, [assignedLeads]);

  useEffect(() => {
    const filtered = assignedLeads.flatMap((lead) =>
      lead.leads.filter((leadData) => {
        const matchesStatus =
          selectedStatus === "" || leadData.Status === selectedStatus;
        const matchesTimezone =
          selectedTimezone === "" || leadData.Timezone === selectedTimezone;
        const matchesCompanyName =
          searchCompanyName === "" ||
          leadData.Lead.toLowerCase().includes(searchCompanyName.toLowerCase());

        return matchesStatus && matchesTimezone && matchesCompanyName;
      })
    );
    setFilteredLeads(filtered);
  }, [assignedLeads, selectedStatus, selectedTimezone, searchCompanyName]);

  console.log("assignedLeads", assignedLeads);
  console.log("Status Options:", statusOptions);
  console.log("Timezone Options:", timezoneOptions);
  console.log("filteredLeads",filteredLeads);

  return (
    <div className="table-container">
      <div className="assign-leads-filters-contanier">
        <div className="assign-lead-filter-contain">
          <label htmlFor="status-select" style={{color:"#BD904E", fontSize:18, fontWeight:600, letterSpacing:1}}>Status:</label>
          <select
            id="status-select"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            style={{
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          >
            <option value="">Select Status</option>
            {statusOptions.map((status, index) => (
              <option key={index} value={status}>
                {status}
              </option>
            ))}
          </select>
        </div>
        <div className="assign-lead-filter-contain">
          <label htmlFor="timezone-select" style={{color:"#BD904E", fontSize:18, fontWeight:600, letterSpacing:1}}>Timezone:</label>
          <select
            id="timezone-select"
            value={selectedTimezone}
            onChange={(e) => setSelectedTimezone(e.target.value)}
            style={{
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          >
            <option value="">Select Timezone</option>
            {timezoneOptions.map((timezone, index) => (
              <option key={index} value={timezone}>
                {timezone}
              </option>
            ))}
          </select>
        </div>
        <div className="assign-lead-filter-contain">
          <label htmlFor="company-search" style={{ color: "#BD904E", fontSize: 18, fontWeight: 600, letterSpacing: 1 }}>
            Company Name:
          </label>
          <input
            type="text"
            id="company-search"
            value={searchCompanyName}
            onChange={(e) => setSearchCompanyName(e.target.value)}
            placeholder="Search by Company Name"
            style={{
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              marginLeft: "10px",
              width: "200px", // Set a width for the input
            }}
          />
        </div>
      </div>
      <table>
        <thead>
          <tr>
            {columnsOfTable.map((column) => (
              <th key={column.field}>{column.headerName}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredLeads.length > 0 ? (
            filteredLeads.map((leadData, index) => (
              <tr key={leadData._id}>
                <td className="td-main">{leadData.Lead || "N/A"}</td>
                <td className="td-main1">
                  {assignedLeads.find((lead) =>
                    lead.leads.some((l) => l._id === leadData._id)
                  ).show.Show || "N/A"}
                </td>
                <td className="td-main2">{leadData.Phone || "N/A"}</td>
                <td>{leadData.Timezone || "N/A"}</td>
                <td>{leadData.Status || "N/A"}</td>
                <td>{leadData.agentName || "N/A"}</td>
                <td>{leadData.closerStatus || "N/A"}</td>
                <td>{leadData.agentName || "N/A"}</td>
                <td>
                  <button
                    onClick={() =>
                      handlePopUpOpen(
                        leadData.showId,
                        leadData._id,
                        assignedLeads.find((lead) =>
                          lead.leads.some((l) => l._id === leadData._id)
                        ).show.Show,
                        leadData.Lead,
                        leadData.Timezone,
                        leadData.Status,
                        leadData["Closer Note"],
                        leadData["Rejection Reason"],
                        leadData.Notes,
                        leadData["DM Name"],
                        leadData["DM Rating"],
                        leadData.Email,
                        leadData["Days to Call"],
                        leadData["Last Contact"],
                        leadData["Closing Date"],
                        leadData.Hotel,
                        leadData.Budget,
                        leadData.Rooms,
                        leadData["Check-in"],
                        leadData["Check-out"],
                        leadData["/ Room Stay"],
                        leadData["Total Stay"],
                        leadData["Lead Date"],
                        leadData["Email Type"]
                      )
                    }
                  >
                    Answer
                  </button>
                </td>
                <td>
                  <button>Email</button>
                </td>
                <td>
                  <button>Feedback</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columnsOfTable.length} style={{ textAlign: "center" }}>
              No Leads Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AssignedLeadsTable;