import React, { useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

const LoginForm = () => {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const role = await login(userEmail, password);
      // Ensure role is available
      if (role) {
        switch (role) {
          case "agent":
            navigate("/agent");
            break;
          case "super user":
            navigate("/super-user");
            break;
          case "team leader":
            navigate("/team-leader");
            break;
          case "pricing junior":
          case "pricing senior":
            navigate("/pricing");
            break;
          case "lead generator":
            navigate("/leads-manager");
            break;
          case "locator":
            navigate("/locator-page");
            break;
          case "sales support":
          case "email team":
          case "email team manager":
          case "sales support manager":
            navigate("/support-landing");
            break;
          case "leads junior":
          case "leads senior":
            navigate("/leads-manager");
            break;
          case "sales manager":
            navigate("/sales-manager");
            break;
          default:
            navigate("/");
        }
      } else {
        // Handle case where role is undefined
        navigate("/");
      }
    } catch (error) {
      console.log(error);

      setLoginError(error.response.data.message);
    }

    setUserEmail("");
    setPassword("");
  };

  return (
    <div className="form-control-container">
      <form className="form-control" onSubmit={handleSubmit}>
        <div className="inputs-form-controls">
          <input
            type="email"
            placeholder="User Email"
            name="email"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="buttons-form-controls">
          <button className="form-btn" disabled={!userEmail || !password}>
            Login Now
          </button>
        </div>
        {loginError && <p className="error">{loginError}</p>}
      </form>
    </div>
  );
};

export default LoginForm;
