import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import LiveRequestCard from "./LiveRequestCard";
import styles from "./SalesSupportPage.module.css";
import { Button } from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import { baseUrl } from "../../api/Api";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
} from "@mui/material";

const EmailTeamPage = () => {
  const [emailRequests, setEmailRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [loading, setLoading] = useState(true);
  const { token } = useContext(AuthContext);
  const [selectedRowId, setSelectedRowId] = useState(null);
  console.log("emailRequests",emailRequests);
  console.log("selectedRequest",selectedRequest);

  

  const filteredHotelData = selectedRequest
    ? emailRequests
        .filter((item) => item.ShowId === selectedRequest.ShowId) // Filter by ShowId
        .flatMap((item) =>
          item.pricingData.flatMap((hotel) => hotel.Hotels.map((h) => h))
        )
    : [];

  useEffect(() => {
    const fetchEmailRequests = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/email-team/email-requests`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setEmailRequests(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching live requests:", error);
      }
    };

    fetchEmailRequests();
  }, []);

  const handleCardClick = (request) => {
    setSelectedRequest(request);
    // console.log("request", request);
  };

  const updateEmailFeedback = async (feedback) => {
    if (!selectedRequest) return;

    try {
      const response = await axios.post(
        `${baseUrl}/api/email-team/update-email-feedback`,
        {
          showId: selectedRequest.ShowId,
          leadId: selectedRequest.CompanyId,
          emailFeedback: feedback,
          requestId: selectedRequest._id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Feedback sent:", response.data);

      // Update the emailRequests state to reflect the feedback change
      setEmailRequests((prevRequests) =>
        prevRequests.map((req) =>
          req.CompanyId === selectedRequest.CompanyId
            ? {
                ...req,
                companyData: { ...req.companyData, emailFeedback: feedback },
              }
            : req
        )
      );
    } catch (error) {
      console.error("Error sending feedback:", error);
    }
  };

  const handleRowClick = (id) => {
    setSelectedRowId(id);
  };

  if (loading) {
    return (
      <div className="spinner-container">
        Loading...
        <div className="spinner"></div>
      </div>
    );
  }
  console.log(selectedRequest);

  return (
    <div className={styles.container}>
      <h1>Email Team Page</h1>
      <div className={styles.content}>
        <ul className={styles.requestList}>
          <h2>Email Requests</h2>
          {emailRequests
            .slice()
            .reverse()
            .map((request, index) => (
              <li
                key={request._id}
                onClick={() => handleCardClick(request)}
                style={{
                  background:
                    request.companyData?.emailFeedback === "Sent"
                      ? "green"
                      : request.companyData?.emailFeedback === "Error Sent"
                      ? "red"
                      : "transparent",
                  color: "white",
                  borderRadius: "5px",
                }}
              >
                <LiveRequestCard message={{ data: request }} />
              </li>
            ))}
        </ul>
        <div className={styles.requestDetails}>
          <h2>Request Details : </h2>
          {selectedRequest && (
            <div>
              <p>
                <strong>Show Name:</strong> {selectedRequest?.showData?.Show}
              </p>
              <p>
                <strong>Company:</strong> {selectedRequest?.companyData?.Lead}
              </p>
              <p>
                <strong>Email:</strong> {selectedRequest?.Email}
              </p>
              <p>
                <strong>Contact Name:</strong>{" "}
                {selectedRequest?.ContactName}
              </p>

              <p>
                <strong>Request:</strong> {selectedRequest?.Request}
              </p>
              <p>
                <strong>Agent Name:</strong> {selectedRequest?.agentName}
              </p>
              <p>
                <strong>Requested Data:</strong> 
                {selectedRequest?.RequestedData}
              </p>
              <p>
                <strong>Email notes:</strong> 
                {selectedRequest?.notes}
              </p>
              <p>
                <strong>State:</strong> {selectedRequest?.State}
              </p>
              <Button
                variant="outlined"
                style={{
                  marginTop: "1rem",
                  marginRight: "0.5rem",
                  backgroundImage: "linear-gradient(90deg, #32CD32, #228B22)",
                  border: "none",
                }}
                onClick={() => updateEmailFeedback("Sent")}
              >
                Send Correct Email
              </Button>

              <Button
                variant="outlined"
                style={{
                  marginTop: "1rem",
                  marginLeft: "0.5rem",
                  backgroundImage: "linear-gradient(90deg, #FF6347, #B22222)",
                  border: "none",
                }}
                onClick={() => updateEmailFeedback("Error Sent")}
              >
                Send Error Email
              </Button>
            </div>
          )}
          {selectedRequest && (
            <div className={styles.emailPriceTable__container}>
              <TableContainer
                component={Paper}
                sx={{
                  overflow: "auto",
                  maxHeight: "600px",
                  // minWidth: "800px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                  maxWidth: "100%",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      {[
                        "Hotel Name",
                        "Address",
                        "Stars",
                        "Selling Price",
                        "Boarding Type",
                        "Distance",
                        "Room Type",
                        "Free Cancellation Until",
                        "Online Price",
                      ].map((header, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            background: "#8b5a5a",
                            color: "white",
                            // whiteSpace: "nowrap",
                            // wordBreak: "keep-all",
                          }}
                        >
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedRequest.pricingData[0].Hotels.map(
                      (hotel, index) => (
                        <TableRow
                          key={hotel._id}
                          onClick={() => handleRowClick(hotel._id)}
                          sx={{
                            background:
                              selectedRowId === hotel._id && "#A9A9A9",
                            cursor: "pointer",
                            color:
                              selectedRowId === hotel._id ? "#fff" : "inherit",
                            "&:hover": {
                              background: "#A9A9A9",
                              color: '"#fff',
                            },
                          }}
                        >
                          <TableCell
                            sx={
                              {
                                // whiteSpace: "nowrap",
                                // wordBreak: "keep-all",
                              }
                            }
                          >
                            {hotel["Hotel Name"]}
                          </TableCell>
                          <TableCell>{hotel.Address}</TableCell>
                          <TableCell>{hotel.Stars}</TableCell>
                          <TableCell>
                            {hotel.SellingPrice
                              ? hotel.SellingPrice
                              : hotel["Average Nightly Rate with Markup"]}
                          </TableCell>
                          <TableCell>{hotel["Boarding Type"]}</TableCell>
                          <TableCell
                            sx={{
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                            }}
                          >
                            {hotel.Distance}
                          </TableCell>
                          <TableCell
                            sx={{
                              whiteSpace: "nowrap",
                              wordBreak: "keep-all",
                            }}
                          >
                            {hotel["Room Type"]}
                          </TableCell>
                          <TableCell>
                            {hotel["Free Cancellation Until"]}
                          </TableCell>
                          <TableCell>{hotel["Online Price"]}</TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default EmailTeamPage;
