import React, { useContext, useState, useRef, useEffect } from "react";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
  Box,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import { baseUrl } from "../../api/Api";

const NewLeadsGeneratorTable = ({ leadsData }) => {
  const [filters, setFilters] = useState({
    Email: false,
    Phone: false,
    History: false,
    Edition: "",
  });
  const [filteredLeads, setFilteredLeads] = useState(
    JSON.parse(localStorage.getItem("filteredLeads")) || []
  );
  const [editingCell, setEditingCell] = useState(null);
  const [editableValues, setEditableValues] = useState({});
  const [createdLeadsCount, setCreatedLeadsCount] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isDataEdited, setIsDataEdited] = useState(false);
  const { token } = useContext(AuthContext);
  const cellRefs = useRef({});

  useEffect(() => {
    const storedFilters = JSON.parse(localStorage.getItem("filters"));
    const storedIsDataEdited = JSON.parse(localStorage.getItem("isDataEdited"));

    if (storedFilters) {
      setFilters(storedFilters);
    }
    if (storedIsDataEdited !== null) {
      setIsDataEdited(storedIsDataEdited);
    }
  }, []);

  // Save filters and isDataEdited to local storage whenever they change
  useEffect(() => {
    localStorage.setItem("filters", JSON.stringify(filters));
    localStorage.setItem("isDataEdited", JSON.stringify(isDataEdited));
  }, [filters, isDataEdited]);

  const columnsOfTable = [
    { field: "No", headerName: "NO" },
    { field: "Lead", headerName: "Lead" },
    { field: "Phone", headerName: "Phone" },
    { field: "Email", headerName: "Email" },
    { field: "Timezone", headerName: "Timezone" },
    { field: "DM Name", headerName: "DM Name" },
    { field: "Title", headerName: "Title" },
  ];

  const handleFilterChange = (event) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleSubmitFilters = async () => {
    try {
      const filtersArray = Object.keys(filters)
        .filter((key) => filters[key] && key !== "Edition")
        .concat(filters.Edition ? { Edition: filters.Edition } : []);

      const response = await axios.post(
        `${baseUrl}/api/companies/leads-with-missing-data`,
        { filters: filtersArray },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log(response);
      setFilteredLeads(response.data);
    } catch (error) {
      console.error("Error fetching filtered leads:", error);
      setSnackbarMessage("Failed to fetch filtered leads. Please try again.");
      setSnackbarOpen(true);
    }
  };

  const handleGetCreatedLeadsCount = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/companies/created-leads-count`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setCreatedLeadsCount(response.data.createdLeadsCount);
    } catch (error) {
      console.error("Error fetching Created leads count:", error);
    }
  };

  useEffect(() => {
    handleGetCreatedLeadsCount();
  }, []);

  const handleCellClick = (leadId, index, field, value, edition) => {
    const editableFields = ["Phone", "Email", "DM Name", "Title"];
    if (editableFields.includes(field)) {
      setEditingCell({ leadId, index, field });
      setEditableValues((prev) => ({ ...prev, [leadId]: value }));
      setTimeout(() => {
        if (cellRefs.current[`${leadId}-${index}-${field}`]) {
          cellRefs.current[`${leadId}-${index}-${field}`].focus();
        }
      }, 0);
    }
  };
  console.log(filteredLeads);

  const handleBlur = (leadId, field) => {
    const updatedLeads = filteredLeads.map((lead) => {
      // Check if the current lead matches the leadId
      if (lead._id === leadId) {
        // Prepare the updated lead with the appropriate field
        const updatedLead = {
          ...lead,
        };
  
        // Update the specific field based on the field parameter
        if (field === "Phone") {
          updatedLead.Phone = editableValues[leadId]; // Update Phone
        } else if (field === "Email") {
          updatedLead.Email = editableValues[leadId]; // Update Email
        }
        
        return updatedLead; // Return the updated lead
      }
      return lead; // Return unchanged lead
    });
  
    console.log("Updated Leads:", updatedLeads);
  
    // Set the updated leads in the state
    setFilteredLeads(updatedLeads);
    setIsDataEdited(true);
    localStorage.setItem("filteredLeads", JSON.stringify(updatedLeads));
    // Reset the editing state
    setEditingCell(null);
  };
  

  const handleKeyDown = (e, leadId, index, field) => {
    if (e.key === "Enter") {
      handleBlur(leadId, index, field);
    }
  };

  const allLeads = filteredLeads;

  const handleUpdateLeads = async () => {
    const payload = allLeads
      .filter((lead) => {
        const isPhoneValid =
          lead.Phone &&
          lead.Phone.trim() !== "" &&
          lead.Phone.trim().toLowerCase() !== "n/a" &&
          lead.Phone.trim().toLowerCase() !== "unknown";

        const isEmailValid =
          lead.Email &&
          lead.Email.trim() !== "" &&
          lead.Email.trim().toLowerCase() !== "n/a" &&
          lead.Email.trim().toLowerCase() !== "unknown";

        return isPhoneValid && isEmailValid;
      })
      .map((lead) => ({
        _id: lead._id,
        edition: lead.edition,
        Phone: lead.Phone,
        Email: lead.Email,
      }));

    try {
      const response = await axios.post(
        `${baseUrl}/api/companies/update-Bulk-of-leads`,
        { payload },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        setSnackbarMessage("Leads updated successfully!");
        setIsDataEdited(false);
        setSnackbarOpen(true);
        // Optionally refresh the filtered leads after update
        await handleSubmitFilters();
        await handleGetCreatedLeadsCount();
      }
    } catch (error) {
      console.error("Error updating leads:", error);
      setSnackbarMessage("Failed to update leads. Please try again.");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box className="table-container">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "2rem",
        }}
      >
        <Typography variant="h5">Filters</Typography>
        <Box
          sx={{
            textAlign: "center",
            border: "1px solid #ccc",
            borderRadius: 2,
            width: "10%",
          }}
        >
          <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
            Created Leads Count
          </Typography>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", color: "#1976d2" }}
          >
            {createdLeadsCount}
          </Typography>
        </Box>
      </div>

      <Box className="filters-container" mb={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} md={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="Email"
                    checked={filters.Email}
                    onChange={handleFilterChange}
                  />
                }
                label="Missing Email"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="Phone"
                    checked={filters.Phone}
                    onChange={handleFilterChange}
                  />
                }
                label="Missing Phone"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="History"
                    checked={filters.History}
                    onChange={handleFilterChange}
                  />
                }
                label="History Leads"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmitFilters}
                sx={{ marginRight: "1rem" }}
                disabled={isDataEdited}
              >
                Apply Filters
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateLeads}
                sx={{ marginRight: "1rem" }}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <TableContainer className="table-scrollable">
        <Table>
          <TableHead>
            <TableRow>
              {columnsOfTable.map((column) => (
                <TableCell key={column.field}>{column.headerName}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {allLeads.map((lead, index) => (
              <TableRow key={lead._id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{lead.Lead}</TableCell>
                <TableCell
                  onClick={() =>
                    handleCellClick(
                      lead._id,
                      index,
                      "Phone",
                      lead.Phone,
                      lead.edition
                    )
                  }
                >
                  {editingCell?.leadId === lead._id &&
                  editingCell.field === "Phone" ? (
                    <TextField
                      value={editableValues[lead._id] || lead.Phone}
                      onBlur={() => handleBlur(lead._id, "Phone", lead.edition)}
                      onChange={(e) =>
                        setEditableValues({
                          ...editableValues,
                          [lead._id]: e.target.value,
                        })
                      }
                      onKeyDown={(e) =>
                        handleKeyDown(e, lead._id, index, "Phone")
                      }
                      inputRef={(el) =>
                        (cellRefs.current[`${lead._id}-${index}-Phone`] = el)
                      }
                    />
                  ) : (
                    lead.Phone
                  )}
                </TableCell>
                <TableCell
                  onClick={() =>
                    handleCellClick(lead._id, index, "Email", lead.Email)
                  }
                >
                  {editingCell?.leadId === lead._id &&
                  editingCell.field === "Email" ? (
                    <TextField
                      value={editableValues[lead._id] || lead.Email}
                      onBlur={() => handleBlur(lead._id, "Email", lead.edition)}
                      onChange={(e) =>
                        setEditableValues({
                          ...editableValues,
                          [lead._id]: e.target.value,
                        })
                      }
                      onKeyDown={(e) =>
                        handleKeyDown(e, lead._id, index, "Email")
                      }
                      inputRef={(el) =>
                        (cellRefs.current[`${lead._id}-${index}-Email`] = el)
                      }
                    />
                  ) : (
                    lead.Email
                  )}
                </TableCell>
                <TableCell>{lead.Timezone}</TableCell>
                <TableCell>{lead["DM Name"]}</TableCell>
                <TableCell>{lead.Title}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NewLeadsGeneratorTable;
