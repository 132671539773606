import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { AuthProvider } from "./components/context/AuthContext";
import Login from "./pages/AuthForms/Login";
import Agent from "./pages/Agent/Agent";
import TeamLeader from "./pages/TeamLeader/TeamLeader";
import SupportLanding from "./pages/SupportLanding/SupportLanding";
import LeadsManager from "./pages/LeadsManager/LeadsManager";
import PricingManager from "./pages/PricingManager/PricingManager";
import SalesSupportPage from "./components/salesSupportTeam/SalesSupportPage";
import NotFound from "./pages/NotFound/NotFound";
import { WebSocketProvider } from "./components/context/websocketContext";
import EmailTeamPage from "./components/salesSupportTeam/EmailTeamPage";
import SuperUser from "./pages/SuperUser/SuperUser";
import Locator from "./pages/Locator/Locator";
import MainNavBar from "./components/mainNavBar/MainNavBar";
import AgentHistoryLeads from "./components/teamLeader/AgentHistoryLeads";
import SalesManager from "./pages/SalesManager/SalesManager";
import CloserLeadsHistory from "./components/salesManager/CloserLeadsHistory";
import AgentLeadsHistory from "./components/salesManager/AgentLeadsHistory";
import ShowLeads from "./components/Locator/ShowLeads";
import ProtectedRoute from "./components/context/ProtectedRoute";

const AppRoutes = () => {
  const location = useLocation();
  const shouldShowNavbar = location.pathname !== "/";

  return (
    <>
      {shouldShowNavbar && <MainNavBar />}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/super-user"
          element={
            <ProtectedRoute allowedRoles={["super user"]}>
              <SuperUser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agent"
          element={
            <ProtectedRoute allowedRoles={["agent"]}>
              <Agent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/team-leader"
          element={
            <ProtectedRoute allowedRoles={["team leader"]}>
              <TeamLeader />
            </ProtectedRoute>
          }
        />
        <Route
          path="/support-landing"
          element={
            <ProtectedRoute
              allowedRoles={[
                "sales support",
                "email team",
                "email team manager",
                "sales support manager",
              ]}
            >
              <SupportLanding />
            </ProtectedRoute>
          }
        />
        <Route
          path="/leads-manager"
          element={
            <ProtectedRoute allowedRoles={["leads junior", "leads senior"]}>
              <LeadsManager />
            </ProtectedRoute>
          }
        />
        <Route
          path="/pricing"
          element={
            <ProtectedRoute allowedRoles={["pricing junior", "pricing senior"]}>
              <PricingManager />
            </ProtectedRoute>
          }
        />
        <Route
          path="/locator-page"
          element={
            <ProtectedRoute allowedRoles={["locator"]}>
              <Locator />
            </ProtectedRoute>
          }
        />
        <Route
          path="/salesSupport-page"
          element={
            <ProtectedRoute
              allowedRoles={["sales support", "sales support manager"]}
            >
              <SalesSupportPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/email-team-page"
          element={
            <ProtectedRoute allowedRoles={["email team", "email team manager"]}>
              <EmailTeamPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agent-history/:id"
          element={
            <ProtectedRoute allowedRoles={["team leader", "sales manager"]}>
              <AgentHistoryLeads />
            </ProtectedRoute>
          }
        />
        <Route
          path="/closer-history/:id"
          element={
            <ProtectedRoute allowedRoles={["team leader", "sales manager"]}>
              <CloserLeadsHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agent-member-history/:id"
          element={
            <ProtectedRoute allowedRoles={["team leader", "sales manager"]}>
              <AgentLeadsHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/leads-of-show/:show"
          element={
            <ProtectedRoute allowedRoles={["locator"]}>
              <ShowLeads />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sales-manager"
          element={
            <ProtectedRoute allowedRoles={["sales manager"]}>
              <SalesManager />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <WebSocketProvider>
          <div className="App">
            <AppRoutes />
          </div>
        </WebSocketProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
