import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useWebSocket } from "../context/websocketContext";
import LiveRequestCard from "./LiveRequestCard";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import styles from "./SalesSupportPage.module.css";
import SalesSupportPricingTable from "./SalesSupportPricingTable";
import { Button, TextField } from "@mui/material"; // Import TextField for text area
import { AuthContext } from "../context/AuthContext";
import { baseUrl } from "../../api/Api";

const SalesSupportPage = () => {
  const { messages } = useWebSocket();
  const [liveRequests, setLiveRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [hotelNames, setHotelNames] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState("");
  const [selectedHotelData, setSelectedHotelData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [customFeedback, setCustomFeedback] = useState(""); // New state for custom feedback
  const { token } = useContext(AuthContext);

  const fetchLiveRequests = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/support/live-requests`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLiveRequests(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching live requests:", error);
    }
  };

  useEffect(() => {
    fetchLiveRequests();
  }, []);

  useEffect(() => {
    messages.forEach((message) => {
      if (message.type === "newRequest") {
        setLiveRequests((prevRequests) => [...prevRequests, message.data]);
      }
    });
  }, [messages]);

  useEffect(() => {
    const fetchHotelNames = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/support/hotel-names`);
        setHotelNames(response.data);
      } catch (error) {
        console.error("Error fetching hotel names:", error);
      }
    };

    fetchHotelNames();
  }, []);

  const handleCardClick = (request) => {
    setSelectedRequest(request);
    // Clear previous feedback when a new request is selected
    setSelectedHotelData([]);
    setCustomFeedback("");
  };

  const handleHotelChange = async (event) => {
    const selectedHotelName = event.target.value;
    setSelectedHotel(selectedHotelName);

    try {
      const response = await axios.get(
        `${baseUrl}/api/support/hotel-data/${selectedHotelName}`
      );
      setSelectedHotelData((prevData) => [...prevData, response.data]);
    } catch (error) {
      console.error("Error fetching hotel data:", error);
    }
  };

  const handleCustomFeedbackChange = (event) => {
    setCustomFeedback(event.target.value); // Update custom feedback state
  };

  const handleSendFeedback = async () => {
    if (!selectedRequest) return;

    try {
      const feedbackData =
        selectedRequest?.Request === "Custom Request"
          ? customFeedback
          : selectedHotelData;

      const response = await axios.post(
        `${baseUrl}/api/support/update-company-feedback`,
        {
          showId: selectedRequest.ShowId,
          companyId: selectedRequest.CompanyId,
          feedback: feedbackData, // Send either hotel data or custom feedback
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Feedback sent:", response.data);

      setSelectedHotelData([]);
      setSelectedHotel("");
      setCustomFeedback(""); // Clear custom feedback after submission
      fetchLiveRequests();
    } catch (error) {
      console.error("Error sending feedback:", error);
    }
  };

  if (loading) {
    return (
      <div className="spinner-container">
        Loading...
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <ul className={styles.requestList}>
          <h2>Live Requests</h2>
          {liveRequests?.slice(0, 4).map((request, index) => (
            <li key={index} onClick={() => handleCardClick(request)}>
              <LiveRequestCard message={{ data: request }} />
            </li>
          ))}
        </ul>
        <div className={styles.requestDetails}>
          <h2>Request Details</h2>
          {selectedRequest && (
            <div>
              <p>
                <strong>Show Name:</strong> {selectedRequest?.showData?.Show}
              </p>
              <p>
                <strong>Company ID:</strong>{" "}
                {selectedRequest?.companyData?.Company}
              </p>
              <p>
                <strong>Request:</strong> {selectedRequest?.Request}
              </p>
              <p>
                <strong>Requested Data:</strong>{" "}
                {selectedRequest?.RequestedData}
              </p>
              <p>
                <strong>Requested Data:</strong> {selectedRequest?.notes}
              </p>
              <p>
                <strong>State:</strong> {selectedRequest?.State}
              </p>

              {selectedRequest?.Request === "Custom Request" ? (
                <TextField
                  label="Custom Feedback"
                  multiline
                  rows={4}
                  value={customFeedback}
                  onChange={handleCustomFeedbackChange}
                  fullWidth
                  sx={{ marginBottom: "1rem" }}
                />
              ) : (
                selectedHotelData && (
                  <div className={styles.tableSection}>
                    <h2>Hotel Data</h2>
                    <SalesSupportPricingTable data={selectedHotelData} />
                  </div>
                )
              )}

              <Button
                variant="outlined"
                style={{ marginTop: "1rem" }}
                onClick={handleSendFeedback}
                disabled={
                  selectedRequest?.Request === "Custom Request"
                    ? customFeedback.length === 0
                    : selectedHotelData.length === 0
                }
              >
                Send Feedback
              </Button>
            </div>
          )}
        </div>

        {selectedRequest?.Request !== "Custom Request" && (
          <div className={styles.filters}>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="hotel-select-label">Hotel</InputLabel>
              <Select
                labelId="hotel-select-label"
                id="hotel-select"
                value={selectedHotel}
                label="Hotel"
                onChange={handleHotelChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {hotelNames.map((hotel, index) => (
                  <MenuItem key={index} value={hotel}>
                    {hotel}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select a hotel to view details</FormHelperText>
            </FormControl>
          </div>
        )}
      </div>
    </div>
  );
};

export default SalesSupportPage;
