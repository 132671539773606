import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { SignUrl, loginUrl, logoutUrl } from "../../api/Api";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();
const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("token") || null);
  const [name, setName] = useState(localStorage.getItem("name") || null);
  const [role, setRole] = useState(localStorage.getItem("role") || null);
  const [timezone, setTimezone] = useState(
    localStorage.getItem("timezone") || null
  );
  const idleTimeout = 60 * 60 * 1000;
  let idleTimer = null;
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, [token, navigate]);

  useEffect(() => {
    const resetIdleTimer = () => {
      clearTimeout(idleTimer);
      idleTimer = setTimeout(logout, idleTimeout);
    };

    window.addEventListener("mousemove", resetIdleTimer);
    window.addEventListener("keydown", resetIdleTimer);

    idleTimer = setTimeout(logout, idleTimeout);

    return () => {
      clearTimeout(idleTimer);
      window.removeEventListener("mousemove", resetIdleTimer);
      window.removeEventListener("keydown", resetIdleTimer);
    };
  }, [token]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      logout();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [token]);

  // Function to update localStorage and state for user data
  const updateUserData = (userData) => {
    setUser(userData);
    setToken(userData.token);
    setRole(userData.role);
    localStorage.setItem("token", userData.token);
    localStorage.setItem("name", userData.name);
    localStorage.setItem("role", userData.role);
    localStorage.setItem("timezone", userData.timezone);
  };

  // =========== login ===========>
  const login = async (email, password) => {
    try {
      const response = await axios.post(loginUrl, { email, password });
      updateUserData(response.data);
      return response.data.role;
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  };

  // =========== signup ===========>
  const signup = async (formData) => {
    console.log(token);

    try {
      const response = await axios.post(SignUrl, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data);
    } catch (error) {
      console.error("Signup error:", error);
      throw error;
    }
  };

  // =========== logout ===========>
  const logout = async () => {
    try {
      await axios.post(
        logoutUrl, // Make sure this URL matches your backend logout endpoint
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Clear user data from state and localStorage
      setUser(null);
      setToken(null);
      setName(null);
      setRole(null);
      setTimezone(null);
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      localStorage.removeItem("role");
      localStorage.removeItem("timezone");
      localStorage.removeItem("filteredLeads");
      localStorage.removeItem("filters");
      localStorage.removeItem("isDataEdited");

      // Optionally, redirect user to the home page or login page
      navigate("/");
    } catch (error) {
      console.error("Logout error:", error);
      // Handle logout error (optional)
    }
  };

  return (
    <AuthContext.Provider
      value={{ user, token, name, role, timezone, login, signup, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
