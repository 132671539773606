import React, { useCallback } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { FaCheckSquare, FaExclamationTriangle } from "react-icons/fa";

const columnsOfTable = [
  { field: "CompanyName", headerName: "Company Name" },
  { field: "ShowName", headerName: "Show Name" },
  { field: "cityState", headerName: "City State" },
  { field: "Phone", headerName: "Phone" },
  { field: "Timezone", headerName: "Timezone" },
  { field: "Status", headerName: "Status" },
  { field: "answerBtn", headerName: "" },
  { field: "liveRequestFeedback", headerName: "Live Request" },
  { field: "emailFeedback", headerName: "Email FeedBack" },
];

const TableOldData = ({ data, handelPopUpOpen, handleFeedbackOpenModal }) => {
  // Calculate the status counts
  const statusCounts = data?.reduce((acc, lead) => {
    lead.Leads?.forEach((companyItem) => {
      const status = companyItem.Status;
      if (status) {
        acc[status] = (acc[status] || 0) + 1;
      }
    });
    return acc;
  }, {});
  // console.log(data);

  const handleFeedback = useCallback(
    (feedback) => {
      handleFeedbackOpenModal(feedback || "");
    },
    [handleFeedbackOpenModal]
  );

  return (
    <div className="table-container old-data-table">
      {/* Display the status counts at the top of the table */}
      {/* <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        {statusCounts &&
          Object.keys(statusCounts).map((status) => (
            <Grid item xs={12} sm={6} md={1} key={status}>
              <Box
                sx={{
                  padding: 1,
                  backgroundColor: "#f5f5f5",
                  borderRadius: "4px",
                  textAlign: "center",
                  width: "120px",
                }}
              >
                <Typography variant="body1" fontWeight="bold">
                  {status}:
                </Typography>
                <Typography variant="body2">{statusCounts[status]}</Typography>
              </Box>
            </Grid>
          ))}
      </Grid> */}

      <Grid container spacing={1.4} sx={{ marginBottom: 2 }}>
        {statusCounts &&
          Object.keys(statusCounts).map((status) => (
            <Grid item xs={12} sm={6} md={1.65} key={status}>
              <Box
                sx={{
                  padding: 1,
                  backgroundColor: "#ffffff",
                  borderRadius: "8px",
                  textAlign: "center",
                  boxShadow: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1" fontWeight="bold">
                  {status}:
                </Typography>
                <Typography variant="body2">{statusCounts[status]}</Typography>
              </Box>
            </Grid>
          ))}
      </Grid>
      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              {columnsOfTable.map((column) => (
                <th key={column.field}>{column.headerName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.map((lead, index) =>
              lead?.Leads?.map((companyItem, companyIndex) => (
                <tr key={`${index}-${companyIndex}`}>
                  <td
                    style={{
                      width: "20%",
                    }}
                  >
                    {companyItem.Lead}
                  </td>
                  <td
                    style={{
                      width: "30%",
                    }}
                  >
                    {lead.Show}
                  </td>
                  <td
                    style={{
                      width: "15%",
                    }}
                  >
                    {companyItem?.cityState || "N/A"}
                  </td>
                  <td
                    style={{
                      width: "20%",
                    }}
                  >
                    {companyItem.Phone}
                  </td>
                  <td>{companyItem.Timezone}</td>
                  <td
                    style={{
                      width: "12%",
                    }}
                  >
                    {companyItem.Status}
                  </td>
                  <td>
                    <button
                      onClick={() => {
                        handelPopUpOpen(
                          companyItem.showId,
                          companyItem._id,
                          lead.Show,
                          companyItem.Lead,
                          companyItem.Timezone,
                          companyItem.Status,
                          companyItem["Rejection Reason"],
                          companyItem.Notes,
                          companyItem["DM Name"],
                          companyItem["DM Rating"],
                          companyItem.Email,
                          companyItem["Closer Assigned"],
                          companyItem["Closer Note"],
                          companyItem["Days to Call"],
                          companyItem["Last Contact"],
                          companyItem["Next Contact"],
                          companyItem["Closing Date"],
                          companyItem.Hotel,
                          companyItem.Budget,
                          companyItem?.Max?.[" Distance"],
                          companyItem.Rooms,
                          companyItem["Check-in"],
                          companyItem["Check-out"],
                          companyItem.roomDetails,
                          companyItem.Activities,
                          companyItem["/ Room Stay"],
                          companyItem["Total Stay"],
                          companyItem["Email Type"],
                          companyItem["Email Notes"]
                        );
                      }}
                    >
                      Answer
                    </button>
                  </td>
                  <td>
                    <button
                      onClick={() =>
                        handleFeedback(companyItem["Request Feedback"])
                      }
                      disabled={
                        !companyItem["Request Feedback"] ||
                        companyItem["Request Feedback"][0] === null
                      }
                    >
                      Feedback
                    </button>
                  </td>
                  <td className="table-icon-feed">
                    {companyItem.emailFeedback === "Error Sent" ? (
                      <FaExclamationTriangle
                        className="error-icon"
                        style={{ color: "red" , fontSize:"20px"}}
                      />
                    ) : (
                      <FaCheckSquare
                        className="check-icon"
                        style={{
                          color:
                            companyItem.emailFeedback === "Sent"
                              ? "green"
                              : "gray",
                        }}
                      />
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableOldData;
