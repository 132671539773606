import React, { useContext, useState } from "react";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";
import "./SupportLanding.css";
import SalesSupportPage from "../../components/salesSupportTeam/SalesSupportPage";
import AllRequestsPage from "../../components/salesSupportTeam/AllRequestsPage";
import EmailTeamPage from "../../components/salesSupportTeam/EmailTeamPage";
import { AuthContext } from "../../components/context/AuthContext";
import EmailTeamHistory from "../../components/salesSupportTeam/EmailTeamHistory";

const SupportLanding = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLiveRequestsActive, setIsLiveRequestsActive] = useState(false);
  const [isEmailRequestsActive, setIsEmailRequestsActive] = useState(false);
  const [isEmailHistoryActive, setIsEmailHistoryActive] = useState(false);

  const [isRequestsHistoryActive, setIsRequestsHistoryActive] = useState(false);

  const { role } = useContext(AuthContext);
  console.log(role);
  const toggleSidebar = () => setIsOpen(!isOpen);

  const showLiveRequests = () => {
    setIsLiveRequestsActive(true);
    setIsRequestsHistoryActive(false);
    setIsEmailRequestsActive(false);
    setIsEmailHistoryActive(false);
  };

  const showEmailRequests = () => {
    setIsRequestsHistoryActive(false);
    setIsLiveRequestsActive(false);
    setIsEmailRequestsActive(true);
    setIsEmailHistoryActive(false);
  };

  const showRequestsHistory = () => {
    setIsRequestsHistoryActive(true);
    setIsLiveRequestsActive(false);
    setIsEmailRequestsActive(false);
    setIsEmailHistoryActive(false);
  };
  const showEmailRequestsHistory = () => {
    setIsRequestsHistoryActive(false);
    setIsLiveRequestsActive(false);
    setIsEmailRequestsActive(false);
    setIsEmailHistoryActive(true);
  };

  return (
    <div className="pricing-manager-container">
      <div className="main-header-info">
        <h2 className="main-header-h2">Sales Support View</h2>
      </div>
      <div className="main-pricing-contain">
        <div className="sidebar-container">
          {isOpen ? (
            <div className="menu-bar-open">
              <MdOutlineClose className="menu-icon" onClick={toggleSidebar} />
              {role === "sales support" && (
                <button onClick={showLiveRequests} className="sidebar-btn">
                  Live Requests
                </button>
              )}
              {role === "email team" && (
                <button onClick={showEmailRequests} className="sidebar-btn">
                  Email Requests
                </button>
              )}
              {role === "email team" && (
                <button
                  onClick={showEmailRequestsHistory}
                  className="sidebar-btn"
                >
                  History
                </button>
              )}
              {(role === "sales support manager" ||
                role === "email team manager") && (
                <button onClick={showRequestsHistory} className="sidebar-btn">
                  Requests History
                </button>
              )}
            </div>
          ) : (
            <div className="menu-bar-close">
              <HiOutlineMenuAlt2
                className="menu-icon"
                onClick={toggleSidebar}
              />
            </div>
          )}
        </div>

        {(role === "sales support" || isLiveRequestsActive) && (
          <div className="table-container">
            <h2 className="table-title">Live Requests Page</h2>
            <SalesSupportPage />
          </div>
        )}
        {role === "email team" && isEmailRequestsActive && (
          <div className="table-container">
            <h2 className="table-title">Email Requests Page</h2>
            <EmailTeamPage />
          </div>
        )}

        {role === "email team" && isEmailHistoryActive && (
          <div className="table-container">
            <h2 className="table-title">Email History Page</h2>
            <EmailTeamHistory />
          </div>
        )}

        {(role === "sales support manager" ||
          role === "email team manager" ||
          isRequestsHistoryActive) && (
          <div className="table-container">
            <h2 className="table-title">Requests History</h2>
            <AllRequestsPage />
          </div>
        )}

        {/* {isLiveRequestsActive && (
          <div className="table-container">
            <h2 className="table-title">Live Requests Page</h2>
            <SalesSupportPage />
          </div>
        )} */}

        {/* {isEmailRequestsActive && (
          <div className="table-container">
            <h2 className="table-title">Email Requests Page</h2>
            <EmailTeamPage />
          </div>
        )} */}

        {/* {isRequestsHistoryActive && (
          <div className="table-container">
            <h2 className="table-title">Requests History</h2>
            <AllRequestsPage />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default SupportLanding;
