import React, { useEffect, useState } from "react";
import "./PricingEmailRequest.css";
import { Dialog } from "@mui/material";
import { IoClose } from "react-icons/io5";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { baseUrl } from "../../api/Api";
import axios from "axios";

const PricingEmailRequest = ({ token }) => {
  const [emailRequests, setEmailRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [viewedRequests, setViewedRequests] = useState(new Set());
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogForm, setOpenDialogForm] = useState(false);
  const [hotelsArr, setHotelsArr] = useState([]);
  const [hotelInfo, setHotelInfo] = useState({
    hotelName: "",
    stars: "",
    sellingPrice: "",
    cancellationDate: "",
    boardingType: "",
    distance: "",
    address: "",
    roomType: "",
  });

  // Fetch email requests
  useEffect(() => {
    const fetchEmailRequests = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/pricing/email-requests`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch email requests");
        }

        const data = await response.json();
        setEmailRequests(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    const storedViewedRequests =
      JSON.parse(localStorage.getItem("viewedRequests")) || [];
    setViewedRequests(new Set(storedViewedRequests));

    fetchEmailRequests();
  }, [token]);

  const handleViewDetails = (request) => {
    setSelectedRequest(request);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    if (selectedRequest) {
      const updatedViewedRequests = new Set(viewedRequests);
      updatedViewedRequests.add(selectedRequest._id);
      setViewedRequests(updatedViewedRequests);
      localStorage.setItem(
        "viewedRequests",
        JSON.stringify(Array.from(updatedViewedRequests))
      );
    }
    setOpenDialog(false);
  };

  const openFormDialog = () => {
    setOpenDialogForm(true);
  };

  const handleCloseFormDialog = () => {
    setOpenDialogForm(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare hotel data in the required format
    const formattedHotelInfo = {
      "Hotel Name": hotelInfo.hotelName,
      Stars: hotelInfo.stars,
      SellingPrice: hotelInfo.sellingPrice,
      "Free Cancellation Until": hotelInfo.cancellationDate,
      "Boarding Type": hotelInfo.boardingType,
      Distance: hotelInfo.distance,
      Address: hotelInfo.address,
      "Room Type": hotelInfo.roomType,
    };

    // Update hotelsArr with the new hotel info
    const newHotelsArr = [...hotelsArr, formattedHotelInfo];
    setHotelsArr(newHotelsArr);

    // Prepare payload for the API call
    const payload = {
      leadId: selectedRequest.CompanyId,
      showId: selectedRequest.ShowId,
      requestId: selectedRequest._id,
      emailPricingFeedback: newHotelsArr,
    };

    // Send email feedback API request
    await sendEmailFeedback(payload);

    // Reset hotel info state
    setHotelInfo({
      hotelName: "",
      stars: "",
      sellingPrice: "",
      cancellationDate: "",
      boardingType: "",
      distance: "",
      address: "",
      roomType: "",
    });

    handleCloseFormDialog();
  };

  const sendEmailFeedback = async (payload) => {
    try {
      const response = await axios.put(
        `${baseUrl}/api/pricing/send-email-feedback`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Email feedback sent successfully:", response.data);
    } catch (error) {
      console.error("Error sending email feedback:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // const [emailRequests, setEmailRequests] = useState([]);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);
  // const [viewedRequests, setViewedRequests] = useState(new Set());
  // const [selectedRequest, setSelectedRequest] = useState(null);
  // const [openDialog, setOpenDialog] = useState(false);
  // const [openDialogForm, setOpenDialogForm] = useState(false);
  // const [hotelsArr, setHotelsArr] = useState([]);
  // const [hotelInfo, setHotelInfo] = useState({
  //   hotelName: "",
  //   stars: "",
  //   sellingPrice: "",
  //   cancellationDate: "",
  //   boardingType: "",
  //   distance: "",
  //   address: "",
  //   roomType: ""
  // });

  // // Fetch email requests
  // useEffect(() => {
  //   const storedViewedRequests = JSON.parse(localStorage.getItem("viewedRequests")) || [];
  //   setViewedRequests(new Set(storedViewedRequests));

  //   const fetchEmailRequests = async () => {
  //     try {
  //       const response = await fetch(
  //         "http://localhost:4800/api/pricing/email-requests",
  //         {
  //           method: "GET",
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );

  //       if (!response.ok) {
  //         throw new Error("Failed to fetch email requests");
  //       }

  //       const data = await response.json();
  //       setEmailRequests(data); // Update emailRequests state
  //       setLoading(false);
  //     } catch (error) {
  //       setError(error.message);
  //       setLoading(false);
  //     }
  //   };

  //   fetchEmailRequests();
  // }, [token]);

  // const handleViewDetails = (request) => {
  //   setSelectedRequest(request);
  //   setOpenDialog(true);
  // };

  // const handleCloseDialog = () => {
  //   if (selectedRequest) {
  //     const updatedViewedRequests = new Set(viewedRequests);
  //     updatedViewedRequests.add(selectedRequest._id);
  //     setViewedRequests(updatedViewedRequests);
  //     localStorage.setItem(
  //       "viewedRequests",
  //       JSON.stringify(Array.from(updatedViewedRequests))
  //     );
  //   }
  //   setOpenDialog(false);
  // };

  // const openFormDialog = () => {
  //   setOpenDialogForm(true);
  // };

  // const handleCloseFormDialog = () => {
  //   setOpenDialogForm(false);
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   // Prepare hotel data in the required format
  //   const formattedHotelInfo = {
  //     "Hotel Name": hotelInfo.hotelName,
  //     "Stars": hotelInfo.stars,
  //     "SellingPrice": hotelInfo.sellingPrice,
  //     "Free Cancellation Until": hotelInfo.cancellationDate,
  //     "Boarding Type": hotelInfo.boardingType,
  //     "Distance": hotelInfo.distance,
  //     "Address": hotelInfo.address,
  //     "Room Type": hotelInfo.roomType
  //   };

  //   // Update hotelsArr with the new hotel info
  //   const newHotelsArr = [...hotelsArr, formattedHotelInfo];
  //   setHotelsArr(newHotelsArr);

  //   // Prepare payload for the API call
  //   const payload = {
  //     // leadId: selectedRequest?.companyData._id, // Assuming you have this from the selected request
  //     // showId: selectedRequest?.showData.id,      // Assuming you have this from the selected request
  //     // requestId: selectedRequest?._id,           // Assuming you have this from the selected request
  //     // emailPricingFeedback: newHotelsArr

  //     leadId: selectedRequest.CompanyId, // Assuming you have this from the selected request
  //     showId: selectedRequest.ShowId,      // Assuming you have this from the selected request
  //     requestId: selectedRequest._id,           // Assuming you have this from the selected request
  //     emailPricingFeedback: newHotelsArr
  //   };

  //   // Send email feedback API request
  //   sendEmailFeedback(payload);
  //   console.log("selectedRequest:", selectedRequest);

  //   console.log("Hotel Information Submitted:", hotelInfo);
  //   console.log("Updated Hotels Array:", newHotelsArr);

  //   // Reset hotel info state
  //   setHotelInfo({
  //     hotelName: "",
  //     stars: "",
  //     sellingPrice: "",
  //     cancellationDate: "",
  //     boardingType: "",
  //     distance: "",
  //     address: "",
  //     roomType: "",
  //   });

  //   handleCloseFormDialog();
  // };

  // const sendEmailFeedback = async (payload) => {
  //   try {
  //     const response = await fetch("http://localhost:4800/api/pricing/send-email-feedback", {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(payload),
  //     });

  //     if (!response.ok) {
  //       throw new Error("Failed to send email feedback");
  //     }

  //     const result = await response.json();
  //     console.log("Email feedback sent successfully:", result);
  //   } catch (error) {
  //     console.error("Error sending email feedback:", error);
  //   }
  // };

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  // if (error) {
  //   return <div>Error: {error}</div>;
  // }

  return (
    <div className="pricing-email-req-contanier">
      {emailRequests
        .slice()
        .reverse()
        .map((request) => (
          <div
            className="pricing-email-req-contain"
            key={request._id}
            style={{
              border: viewedRequests.has(request._id)
                ? "none"
                : "2px solid red",
            }}
          >
            <h3 className="pricing-email-req-header">Email Request:</h3>
            <div className="pricing-email-req-card">
              <p className="pricing-email-req-card-text">
                <span>Show Name:</span> {request.showData.Show || "N/A"}
              </p>
              <p className="pricing-email-req-card-text">
                <span>Company Name:</span> {request.companyData.Lead || "N/A"}
              </p>
              <p className="pricing-email-req-card-text">
                <span>Request Data:</span> {request.RequestedData || "N/A"}
              </p>
              <p className="pricing-email-req-card-text">
                <span>State:</span> {request.State || "N/A"}
              </p>
            </div>
            <button
              className="pricing-email-req-details"
              onClick={() => handleViewDetails(request)}
            >
              <p>View Details</p>
            </button>
          </div>
        ))}
      {/* Material-UI Dialog for Request Details */}
      <Dialog open={openDialog} onClose={handleCloseDialog} fullScreen>
        <div className="Dialog-pricing-email-contanier">
          <div className="Dialog-pricing-email-header">
            <h3>Email Request Details</h3>
            <button className="close-icon-dialog" onClick={handleCloseDialog}>
              <IoClose />
            </button>
          </div>
          <div className="Dialog-pricing-email-contain">
            <div className="Dialog-pricing-email-info">
              <div className="Dialog-pricing-email-texts">
                <p className="pricing-email-req-card-text">
                  <span>Agent Name:</span> {selectedRequest?.agentName || "N/A"}
                </p>
                <p className="pricing-email-req-card-text">
                  <span>Show Name:</span>{" "}
                  {selectedRequest?.showData.Show || "N/A"}
                </p>
                <p className="pricing-email-req-card-text">
                  <span>Company Name:</span>{" "}
                  {selectedRequest?.companyData.Lead || "N/A"}
                </p>
                <p className="pricing-email-req-card-text">
                  <span>Email:</span>{" "}
                  {selectedRequest?.companyData.Email || "N/A"}
                </p>
                <p className="pricing-email-req-card-text">
                  <span>Request Data:</span>{" "}
                  {selectedRequest?.RequestedData || "N/A"}
                </p>
                <p className="pricing-email-req-card-text">
                  <span>Check-in:</span>{" "}
                  {selectedRequest?.companyData["Check-in"] || "N/A"}
                </p>
                <p className="pricing-email-req-card-text">
                  <span>Check-out:</span>{" "}
                  {selectedRequest?.companyData["Check-out"] || "N/A"}
                </p>
                <p className="pricing-email-req-card-text">
                  <span>No.Rooms:</span>{" "}
                  {selectedRequest?.companyData["/ Room Stay"] || "N/A"}
                </p>
                <p className="pricing-email-req-card-text">
                  <span>Email Note:</span>{" "}
                  {selectedRequest?.companyData.emailRequestNotes || "N/A"}
                </p>
                <p className="pricing-email-req-card-text">
                  <span>State:</span> {selectedRequest?.State || "N/A"}
                </p>
              </div>
              <button
                className="pricing-email-req-details"
                onClick={openFormDialog}
              >
                <p>Add Hotels</p>
              </button>
            </div>
            <div className="table-pricing-req-contanier">
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "1rem",
                          fontFamily: "'Roboto', sans-serif",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Hotel Name
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "1rem",
                          fontFamily: "'Roboto', sans-serif",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Stars
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "1rem",
                          fontFamily: "'Roboto', sans-serif",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Selling Price
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "1rem",
                          fontFamily: "'Roboto', sans-serif",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Free Cancellation Until
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "1rem",
                          fontFamily: "'Roboto', sans-serif",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Boarding Type
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "1rem",
                          fontFamily: "'Roboto', sans-serif",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Distance
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "1rem",
                          fontFamily: "'Roboto', sans-serif",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Address
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "1rem",
                          fontFamily: "'Roboto', sans-serif",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Room Type
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {hotelsArr.length > 0 ? (
                      hotelsArr.map((hotel, index) => {
                        console.log("Rendering hotel:", hotel); // Log each hotel
                        return (
                          <TableRow key={index}>
                            <TableCell>{hotel["Hotel Name"]}</TableCell>
                            <TableCell>{hotel["Stars"]}</TableCell>
                            <TableCell>{hotel["SellingPrice"]}</TableCell>
                            <TableCell>
                              {hotel["Free Cancellation Until"]}
                            </TableCell>
                            <TableCell>{hotel["Boarding Type"]}</TableCell>
                            <TableCell>{hotel["Distance"]}</TableCell>
                            <TableCell>{hotel["Address"]}</TableCell>
                            <TableCell>{hotel["Room Type"]}</TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={8} style={{ textAlign: "center" }}>
                          No data available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </Dialog>
      {/* Material-UI Dialog for Request Details */}
      <Dialog open={openDialogForm}>
        <div
          style={{
            padding: "25px",
            backgroundColor: "#ffffff",
            borderRadius: "8px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            maxWidth: "400px",
            margin: "0 auto",
          }}
        >
          <form
            onSubmit={handleSubmit}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div style={{ marginBottom: "12px" }}>
              <label
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                  display: "block",
                }}
              >
                Hotel Name:
              </label>
              <input
                type="text"
                name="hotelName"
                value={hotelInfo.hotelName}
                onChange={(e) =>
                  setHotelInfo({ ...hotelInfo, hotelName: e.target.value })
                }
                required
                style={{
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "1rem",
                  width: "100%",
                }}
              />
            </div>
            <div style={{ marginBottom: "12px" }}>
              <label
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                  display: "block",
                }}
              >
                Stars:
              </label>
              <input
                type="number"
                name="stars"
                value={hotelInfo.stars}
                min="1"
                max="5"
                onChange={(e) =>
                  setHotelInfo({ ...hotelInfo, stars: e.target.value })
                }
                required
                style={{
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "1rem",
                  width: "100%",
                }}
              />
            </div>
            <div style={{ marginBottom: "12px" }}>
              <label
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                  display: "block",
                }}
              >
                Selling Price:
              </label>
              <input
                type="text"
                name="sellingPrice"
                value={hotelInfo.sellingPrice}
                onChange={(e) =>
                  setHotelInfo({ ...hotelInfo, sellingPrice: e.target.value })
                }
                required
                style={{
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "1rem",
                  width: "100%",
                }}
              />
            </div>
            <div style={{ marginBottom: "12px" }}>
              <label
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                  display: "block",
                }}
              >
                Free Cancellation Until:
              </label>
              <input
                type="date"
                name="cancellationDate"
                value={hotelInfo.cancellationDate}
                onChange={(e) =>
                  setHotelInfo({
                    ...hotelInfo,
                    cancellationDate: e.target.value,
                  })
                }
                required
                style={{
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "1rem",
                  width: "100%",
                }}
              />
            </div>
            <div style={{ marginBottom: "12px" }}>
              <label
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                  display: "block",
                }}
              >
                Boarding Type:
              </label>
              <input
                type="text"
                name="boardingType"
                value={hotelInfo.boardingType}
                onChange={(e) =>
                  setHotelInfo({ ...hotelInfo, boardingType: e.target.value })
                }
                required
                style={{
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "1rem",
                  width: "100%",
                }}
              />
            </div>
            <div style={{ marginBottom: "12px" }}>
              <label
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                  display: "block",
                }}
              >
                Distance:
              </label>
              <input
                type="text"
                name="distance"
                value={hotelInfo.distance}
                onChange={(e) =>
                  setHotelInfo({ ...hotelInfo, distance: e.target.value })
                }
                required
                style={{
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "1rem",
                  width: "100%",
                }}
              />
            </div>
            <div style={{ marginBottom: "12px" }}>
              <label
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                  display: "block",
                }}
              >
                Address:
              </label>
              <input
                type="text"
                name="address"
                value={hotelInfo.address}
                onChange={(e) =>
                  setHotelInfo({ ...hotelInfo, address: e.target.value })
                }
                required
                style={{
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "1rem",
                  width: "100%",
                }}
              />
            </div>
            <div style={{ marginBottom: "12px" }}>
              <label
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                  display: "block",
                }}
              >
                Room Type:
              </label>
              <input
                type="text"
                name="roomType"
                value={hotelInfo.roomType}
                onChange={(e) =>
                  setHotelInfo({ ...hotelInfo, roomType: e.target.value })
                }
                required
                style={{
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "1rem",
                  width: "100%",
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "14px",
              }}
            >
              <button
                type="submit"
                style={{
                  padding: "10px",
                  backgroundColor: "#007bff",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  fontSize: "1rem",
                  flex: "1",
                  marginRight: "10px",
                }}
              >
                Add Hotel
              </button>
              <button
                type="button"
                onClick={handleCloseFormDialog}
                style={{
                  padding: "10px",
                  backgroundColor: "#dc3545",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  fontSize: "1rem",
                  flex: "1",
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default PricingEmailRequest;

// import React, { useEffect, useState } from "react";
// import "./PricingEmailRequest.css";
// import { Dialog } from "@mui/material";
// import { IoClose } from "react-icons/io5";
// import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper} from "@mui/material";

// const PricingEmailRequest = ({ token }) => {
//   const [emailRequests, setEmailRequests] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [viewedRequests, setViewedRequests] = useState(new Set());
//   const [selectedRequest, setSelectedRequest] = useState(null);
//   const [openDialog, setOpenDialog] = useState(false);
//   const [openDialogForm, setOpenDialogForm] = useState(false);
//   const [hotelsArr, setHotelsArr] = useState([]);
//   const [hotelInfo, setHotelInfo] = useState({
//     hotelName: "",
//     stars: "",
//     sellingPrice: "",
//     cancellationDate: "",
//     boardingType: "",
//     distance: "",
//     address: "",
//     roomType: ""
// });

//   useEffect(() => {
//     const storedViewedRequests =
//       JSON.parse(localStorage.getItem("viewedRequests")) || [];
//     setViewedRequests(new Set(storedViewedRequests));

//     const fetchEmailRequests = async () => {
//       try {
//         const response = await fetch(
//           "http://localhost:4800/api/pricing/email-requests",
//           {
//             method: "GET",
//             headers: {
//               Authorization: `Bearer ${token}`,
//               "Content-Type": "application/json",
//             },
//           }
//         );

//         if (!response.ok) {
//           throw new Error("Failed to fetch email requests");
//         }

//         const data = await response.json();
//         setEmailRequests(data); // Update emailRequests state
//         setLoading(false);
//       } catch (error) {
//         setError(error.message);
//         setLoading(false);
//       }
//     };

//     fetchEmailRequests();
//   }, [token]);

//   const handleViewDetails = (request) => {
//     setSelectedRequest(request);
//     setOpenDialog(true);
//   };

//   const handleCloseDialog = () => {
//     if (selectedRequest) {
//       const updatedViewedRequests = new Set(viewedRequests);
//       updatedViewedRequests.add(selectedRequest._id);
//       setViewedRequests(updatedViewedRequests);
//       localStorage.setItem(
//         "viewedRequests",
//         JSON.stringify(Array.from(updatedViewedRequests))
//       );
//     }
//     setOpenDialog(false);
//   };

//   const openFormDialog = () => {
//     setOpenDialogForm(true);
//   };

//   const handleCloseFormDialog = () => {
//     setOpenDialogForm(false);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     setHotelsArr((prevHotelsArr) => [...prevHotelsArr, hotelInfo]);

//     console.log("Hotel Information Submitted:", hotelInfo);
//     console.log("Updated Hotels Array:", hotelsArr);

//     setHotelInfo({
//       hotelName: "",
//       stars: "",
//       sellingPrice: "",
//       cancellationDate: "",
//       boardingType: "",
//       distance: "",
//       address: "",
//       roomType: "",
//     });

//     handleCloseFormDialog();
//   };

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   return (
//     <div className="pricing-email-req-contanier">
//       {emailRequests
//         .slice()
//         .reverse()
//         .map((request) => (
//           <div
//             className="pricing-email-req-contain"
//             key={request._id}
//             style={{
//               border: viewedRequests.has(request._id)
//                 ? "none"
//                 : "2px solid red",
//             }}
//           >
//             <h3 className="pricing-email-req-header">Email Request:</h3>
//             <div className="pricing-email-req-card">
//               <p className="pricing-email-req-card-text">
//                 <span>Show Name:</span> {request.showData.Show || "N/A"}
//               </p>
//               <p className="pricing-email-req-card-text">
//                 <span>Company Name:</span> {request.companyData.Lead || "N/A"}
//               </p>
//               <p className="pricing-email-req-card-text">
//                 <span>Request Data:</span> {request.RequestedData || "N/A"}
//               </p>
//               <p className="pricing-email-req-card-text">
//                 <span>State:</span> {request.State || "N/A"}
//               </p>
//             </div>
//             <button
//               className="pricing-email-req-details"
//               onClick={() => handleViewDetails(request)}
//             >
//               <p>View Details</p>
//             </button>
//           </div>
//         ))}
//       {/* Material-UI Dialog for Request Details */}
//       <Dialog open={openDialog} onClose={handleCloseDialog} fullScreen>
//         <div className="Dialog-pricing-email-contanier">
//           <div className="Dialog-pricing-email-header">
//             <h3>Email Request Details</h3>
//             <button className="close-icon-dialog" onClick={handleCloseDialog}>
//               <IoClose />
//             </button>
//           </div>
//           <div className="Dialog-pricing-email-contain">
//             <div className="Dialog-pricing-email-info">
//               <div className="Dialog-pricing-email-texts">
//                 <p className="pricing-email-req-card-text">
//                   <span>Agent Name:</span> {selectedRequest?.agentName || "N/A"}
//                 </p>
//                 <p className="pricing-email-req-card-text">
//                   <span>Show Name:</span>{" "}
//                   {selectedRequest?.showData.Show || "N/A"}
//                 </p>
//                 <p className="pricing-email-req-card-text">
//                   <span>Company Name:</span>{" "}
//                   {selectedRequest?.companyData.Lead || "N/A"}
//                 </p>
//                 <p className="pricing-email-req-card-text">
//                   <span>Email:</span>{" "}
//                   {selectedRequest?.companyData.Email || "N/A"}
//                 </p>
//                 <p className="pricing-email-req-card-text">
//                   <span>Request Data:</span>{" "}
//                   {selectedRequest?.RequestedData || "N/A"}
//                 </p>
//                 <p className="pricing-email-req-card-text">
//                   <span>Check-in:</span>{" "}
//                   {selectedRequest?.companyData["Check-in"] || "N/A"}
//                 </p>
//                 <p className="pricing-email-req-card-text">
//                   <span>Check-out:</span>{" "}
//                   {selectedRequest?.companyData["Check-out"] || "N/A"}
//                 </p>
//                 <p className="pricing-email-req-card-text">
//                   <span>No.Rooms:</span>{" "}
//                   {selectedRequest?.companyData["/ Room Stay"] || "N/A"}
//                 </p>
//                 <p className="pricing-email-req-card-text">
//                   <span>Email Note:</span>{" "}
//                   {selectedRequest?.companyData.emailRequestNotes || "N/A"}
//                 </p>
//                 <p className="pricing-email-req-card-text">
//                   <span>State:</span> {selectedRequest?.State || "N/A"}
//                 </p>
//               </div>
//               <button
//                 className="pricing-email-req-details"
//                 onClick={openFormDialog}
//               >
//                 <p>Add Hotels</p>
//               </button>
//             </div>
//             <div className="table-pricing-req-contanier">
//     <TableContainer component={Paper}>
//       <Table>
//         <TableHead>
//           <TableRow>
//             <TableCell
//               sx={{
//                 color: "white",
//                 fontWeight: "bold",
//                 fontSize: "1rem",
//                 fontFamily: "'Roboto', sans-serif",
//                 whiteSpace: "nowrap"
//               }}
//             >
//               Hotel Name
//             </TableCell>
//             <TableCell
//               sx={{
//                 color: "white",
//                 fontWeight: "bold",
//                 fontSize: "1rem",
//                 fontFamily: "'Roboto', sans-serif",
//                 whiteSpace: "nowrap"
//               }}
//             >
//               Stars
//             </TableCell>
//             <TableCell
//               sx={{
//                 color: "white",
//                 fontWeight: "bold",
//                 fontSize: "1rem",
//                 fontFamily: "'Roboto', sans-serif",
//                 whiteSpace: "nowrap"
//               }}
//             >
//               Selling Price
//             </TableCell>
//             <TableCell
//               sx={{
//                 color: "white",
//                 fontWeight: "bold",
//                 fontSize: "1rem",
//                 fontFamily: "'Roboto', sans-serif",
//                 whiteSpace: "nowrap"
//               }}
//             >
//               Free Cancellation Until
//             </TableCell>
//             <TableCell
//               sx={{
//                 color: "white",
//                 fontWeight: "bold",
//                 fontSize: "1rem",
//                 fontFamily: "'Roboto', sans-serif",
//                 whiteSpace: "nowrap"
//               }}
//             >
//               Boarding Type
//             </TableCell>
//             <TableCell
//               sx={{
//                 color: "white",
//                 fontWeight: "bold",
//                 fontSize: "1rem",
//                 fontFamily: "'Roboto', sans-serif",
//                 whiteSpace: "nowrap"
//               }}
//             >
//               Distance
//             </TableCell>
//             <TableCell
//               sx={{
//                 color: "white",
//                 fontWeight: "bold",
//                 fontSize: "1rem",
//                 fontFamily: "'Roboto', sans-serif",
//                 whiteSpace: "nowrap"
//               }}
//             >
//               Address
//             </TableCell>
//             <TableCell
//               sx={{
//                 color: "white",
//                 fontWeight: "bold",
//                 fontSize: "1rem",
//                 fontFamily: "'Roboto', sans-serif",
//                 whiteSpace: "nowrap"
//               }}
//             >
//               Room Type
//             </TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {hotelsArr.length > 0 ? (
//             hotelsArr.map((hotel, index) => (
//               <TableRow key={index}>
//                 <TableCell>{hotel.hotelName}</TableCell>
//                 <TableCell>{hotel.stars}</TableCell>
//                 <TableCell>{hotel.sellingPrice}</TableCell>
//                 <TableCell>{hotel.cancellationDate}</TableCell>
//                 <TableCell>{hotel.boardingType}</TableCell>
//                 <TableCell>{hotel.distance}</TableCell>
//                 <TableCell>{hotel.address}</TableCell>
//                 <TableCell>{hotel.roomType}</TableCell>
//               </TableRow>
//             ))
//           ) : (
//             <TableRow>
//               <TableCell colSpan={8} style={{ textAlign: 'center' }}>
//                 No data available
//               </TableCell>
//             </TableRow>
//           )}
//         </TableBody>
//       </Table>
//     </TableContainer>
//             </div>
//           </div>
//         </div>
//       </Dialog>
//       {/* Material-UI Dialog for Request Details */}
//       <Dialog open={openDialogForm}>
//         <div
//           style={{
//             padding: "25px",
//             backgroundColor: "#ffffff",
//             borderRadius: "8px",
//             boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
//             maxWidth: "400px",
//             margin: "0 auto",
//           }}
//         >
//           <form
//             onSubmit={handleSubmit}
//             style={{ display: "flex", flexDirection: "column" }}
//           >
//             <div style={{ marginBottom: "12px" }}>
//               <label
//                 style={{
//                   fontWeight: "bold",
//                   marginBottom: "5px",
//                   display: "block",
//                 }}
//               >
//                 Hotel Name:
//               </label>
//               <input
//                 type="text"
//                 name="hotelName"
//                 value={hotelInfo.hotelName}
//                 onChange={(e) =>
//                   setHotelInfo({ ...hotelInfo, hotelName: e.target.value })
//                 }
//                 required
//                 style={{
//                   padding: "8px",
//                   border: "1px solid #ccc",
//                   borderRadius: "4px",
//                   fontSize: "1rem",
//                   width: "100%",
//                 }}
//               />
//             </div>
//             <div style={{ marginBottom: "12px" }}>
//               <label
//                 style={{
//                   fontWeight: "bold",
//                   marginBottom: "5px",
//                   display: "block",
//                 }}
//               >
//                 Stars:
//               </label>
//               <input
//                 type="number"
//                 name="stars"
//                 value={hotelInfo.stars}
//                 min="1"
//                 max="5"
//                 onChange={(e) =>
//                   setHotelInfo({ ...hotelInfo, stars: e.target.value })
//                 }
//                 required
//                 style={{
//                   padding: "8px",
//                   border: "1px solid #ccc",
//                   borderRadius: "4px",
//                   fontSize: "1rem",
//                   width: "100%",
//                 }}
//               />
//             </div>
//             <div style={{ marginBottom: "12px" }}>
//               <label
//                 style={{
//                   fontWeight: "bold",
//                   marginBottom: "5px",
//                   display: "block",
//                 }}
//               >
//                 Selling Price:
//               </label>
//               <input
//                 type="text"
//                 name="sellingPrice"
//                 value={hotelInfo.sellingPrice}
//                 onChange={(e) =>
//                   setHotelInfo({ ...hotelInfo, sellingPrice: e.target.value })
//                 }
//                 required
//                 style={{
//                   padding: "8px",
//                   border: "1px solid #ccc",
//                   borderRadius: "4px",
//                   fontSize: "1rem",
//                   width: "100%",
//                 }}
//               />
//             </div>
//             <div style={{ marginBottom: "12px" }}>
//               <label
//                 style={{
//                   fontWeight: "bold",
//                   marginBottom: "5px",
//                   display: "block",
//                 }}
//               >
//                 Free Cancellation Until:
//               </label>
//               <input
//                 type="date"
//                 name="cancellationDate"
//                 value={hotelInfo.cancellationDate}
//                 onChange={(e) =>
//                   setHotelInfo({
//                     ...hotelInfo,
//                     cancellationDate: e.target.value,
//                   })
//                 }
//                 required
//                 style={{
//                   padding: "8px",
//                   border: "1px solid #ccc",
//                   borderRadius: "4px",
//                   fontSize: "1rem",
//                   width: "100%",
//                 }}
//               />
//             </div>
//             <div style={{ marginBottom: "12px" }}>
//               <label
//                 style={{
//                   fontWeight: "bold",
//                   marginBottom: "5px",
//                   display: "block",
//                 }}
//               >
//                 Boarding Type:
//               </label>
//               <input
//                 type="text"
//                 name="boardingType"
//                 value={hotelInfo.boardingType}
//                 onChange={(e) =>
//                   setHotelInfo({ ...hotelInfo, boardingType: e.target.value })
//                 }
//                 required
//                 style={{
//                   padding: "8px",
//                   border: "1px solid #ccc",
//                   borderRadius: "4px",
//                   fontSize: "1rem",
//                   width: "100%",
//                 }}
//               />
//             </div>
//             <div style={{ marginBottom: "12px" }}>
//               <label
//                 style={{
//                   fontWeight: "bold",
//                   marginBottom: "5px",
//                   display: "block",
//                 }}
//               >
//                 Distance:
//               </label>
//               <input
//                 type="text"
//                 name="distance"
//                 value={hotelInfo.distance}
//                 onChange={(e) =>
//                   setHotelInfo({ ...hotelInfo, distance: e.target.value })
//                 }
//                 required
//                 style={{
//                   padding: "8px",
//                   border: "1px solid #ccc",
//                   borderRadius: "4px",
//                   fontSize: "1rem",
//                   width: "100%",
//                 }}
//               />
//             </div>
//             <div style={{ marginBottom: "12px" }}>
//               <label
//                 style={{
//                   fontWeight: "bold",
//                   marginBottom: "5px",
//                   display: "block",
//                 }}
//               >
//                 Address:
//               </label>
//               <input
//                 type="text"
//                 name="address"
//                 value={hotelInfo.address}
//                 onChange={(e) =>
//                   setHotelInfo({ ...hotelInfo, address: e.target.value })
//                 }
//                 required
//                 style={{
//                   padding: "8px",
//                   border: "1px solid #ccc",
//                   borderRadius: "4px",
//                   fontSize: "1rem",
//                   width: "100%",
//                 }}
//               />
//             </div>
//             <div style={{ marginBottom: "12px" }}>
//               <label
//                 style={{
//                   fontWeight: "bold",
//                   marginBottom: "5px",
//                   display: "block",
//                 }}
//               >
//                 Room Type:
//               </label>
//               <input
//                 type="text"
//                 name="roomType"
//                 value={hotelInfo.roomType}
//                 onChange={(e) =>
//                   setHotelInfo({ ...hotelInfo, roomType: e.target.value })
//                 }
//                 required
//                 style={{
//                   padding: "8px",
//                   border: "1px solid #ccc",
//                   borderRadius: "4px",
//                   fontSize: "1rem",
//                   width: "100%",
//                 }}
//               />
//             </div>

//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginTop: "14px",
//               }}
//             >
//               <button
//                 type="submit"
//                 style={{
//                   padding: "10px",
//                   backgroundColor: "#007bff",
//                   color: "white",
//                   border: "none",
//                   borderRadius: "4px",
//                   cursor: "pointer",
//                   fontSize: "1rem",
//                   flex: "1",
//                   marginRight: "10px",
//                 }}
//               >
//                 Add Hotel
//               </button>
//               <button
//                 type="button"
//                 onClick={handleCloseFormDialog}
//                 style={{
//                   padding: "10px",
//                   backgroundColor: "#dc3545",
//                   color: "white",
//                   border: "none",
//                   borderRadius: "4px",
//                   cursor: "pointer",
//                   fontSize: "1rem",
//                   flex: "1",
//                 }}
//               >
//                 Cancel
//               </button>
//             </div>
//           </form>
//         </div>
//       </Dialog>
//     </div>
//   );
// };

// export default PricingEmailRequest;
