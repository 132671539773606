import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";
import CircularProgress from "@mui/material/CircularProgress";
import "./Locator.css";

import { AuthContext } from "../../components/context/AuthContext";
import LocatorTable from "../../components/Locator/LocatorTable";
import UploadLocatorsSection from "../../components/uploadControllers/UploadLocatorSection";
import MatchingTable from "../../components/Locator/MatchingTable";
import LocatorPopup from "../../components/Locator/LocatorPopup";
import HotelsPopup from "../../components/Locator/HotelsPopup";
import { baseUrl } from "../../api/Api";

const Locator = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLocatorTableActive, setIsLocatorTableActive] = useState(true);
  const [isMatchTableActive, setIsMatchTableActive] = useState(false);
  const [isUploadActive, setIsUploadActive] = useState(false);

  const [locatorData, setLocatorData] = useState(null);
  const [rawLeads, setRawLeads] = useState(null);
  const [rawPricings, setRawPricings] = useState(null);
  //////////////////////////////////////////////////////////////////////////////////////
  const [activeRowIdInLead, setActiveRowIdInLead] = useState(null);
  const [activeRowIdInPricing, setActiveRowIdInPricing] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const [formDataLocator, setFormDataLocator] = useState({
    edition: "",
    Show: "",
    "Show Url": "",
    "Next Dates": "",
    "End Date": "",
    Duration: "",
    "Convention Center / Host Hotel": "",
    Venue: "",
    "City, Country": "",
  });

  const { role, token } = useContext(AuthContext);

  const [requestQueue, setRequestQueue] = useState([]);
  const [isProcessingQueue, setIsProcessingQueue] = useState(false);
  const [processIds, setProcessIds] = useState([]);
  const [processStatuses, setProcessStatuses] = useState([]);

  // console.log(role);
  const toggleSidebar = () => setIsOpen(!isOpen);

  const fetchLocatorData = async () => {
    try {
      console.log("Fetching staging data...");
      const response = await axios.get(`${baseUrl}/api/locator/locator-data`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLocatorData(response.data);
      console.log("Staging data fetched:", response.data);
    } catch (error) {
      console.error("Error fetching staging data:", error);
    }
  };

  const fetchRawLeads = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/locator/get-raw-leads`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRawLeads(response.data);
      console.log("Raw Leads", response.data);
    } catch (error) {
      console.log("Error fetching raw leads", error);
    }
  };

  const fetchRawPricings = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/locator/get-raw-pricings`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRawPricings(response.data);
      console.log("Raw Pricings", response.data);
    } catch (error) {
      console.log("Error fetching raw pricings", error);
    }
  };

  useEffect(() => {
    fetchLocatorData();
  }, []);

  useEffect(() => {
    const fetchMatchTableData = async () => {
      await fetchRawLeads();
      await fetchRawPricings();
    };
    if (isMatchTableActive) fetchMatchTableData();
  }, [isMatchTableActive]);

  ///////////////////////////////////////////////////////////////////

  useEffect(() => {
    const processQueueAsync = async () => {
      if (requestQueue.length > 0 && !isProcessingQueue) {
        setIsProcessingQueue(true);
        await processQueue();
      }
    };
    processQueueAsync();
  }, [requestQueue]);

  const processQueue = async () => {
    setIsProcessingQueue(true);
  
    if (requestQueue.length === 0) {
      setIsProcessingQueue(false);
      return;
    }
  
    // Set the status of the first request in the queue to "Pending"
    const currentRequest = requestQueue[0];
    setProcessStatuses((prevStatuses) =>
      prevStatuses.map((req) =>
        req.id === currentRequest.id ? { ...req, status: "Pending" } : req
      )
    );
  
    try {
      const { formDataLocator, activeRowIdInLead, activeRowIdInPricing, resolve } = currentRequest;
      await axios.post(
        `${baseUrl}/api/locator/match-pricing-show/${activeRowIdInLead}/${activeRowIdInPricing}`,
        formDataLocator,
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      // Update the status to "Completed" after successful request
      const updatedStatus = { show: formDataLocator.Show, status: "Completed" };
      setProcessStatuses((prev) =>
        prev.map((req) => (req.id === currentRequest.id ? updatedStatus : req))
      );
  
      // Resolve the promise for this request
      resolve(updatedStatus);
    } catch (error) {
      console.error("Request error:", error);
      setProcessStatuses((prev) =>
        prev.map((req) =>
          req.id === currentRequest.id ? { ...req, status: "Failed" } : req
        )
      );
      // Handle rejection in case of error
      currentRequest.reject(error);
    }
  
    // Remove the completed request from the queue and process the next one
    setRequestQueue((prevQueue) => prevQueue.slice(1));
    setIsProcessingQueue(false);
  };
  
  const addRequestToQueue = (formDataLocator, activeRowIdInLead, activeRowIdInPricing) => {
    return new Promise((resolve, reject) => {
      const newRequest = {
        id: Date.now(), // Unique ID for each request
        formDataLocator,
        activeRowIdInLead,
        activeRowIdInPricing,
        resolve,
        reject,
        status: "Waiting",
      };
  
      // Add the request to the queue and initialize status as "Waiting"
      setRequestQueue((prevQueue) => [...prevQueue, newRequest]);
      setProcessStatuses((prevStatuses) => [
        ...prevStatuses,
        { id: newRequest.id, show: formDataLocator.Show, status: "Waiting" }
      ]);
    });
  };
  

  //////////////////////////////////////////////////

  const showLocatorTable = () => {
    setIsLocatorTableActive(true);
    setIsUploadActive(false);
    setIsMatchTableActive(false);
  };

  const showMatchTable = () => {
    setIsMatchTableActive(true);
    setIsLocatorTableActive(false);
    setIsUploadActive(false);
  };

  const showUploadControllers = () => {
    setIsUploadActive(true);
    setIsLocatorTableActive(false);
    setIsMatchTableActive(false);
  };
  ///////////////////////////////////////////////////////////////////////////////
  const handleLeadRowClick = (id) => {
    console.log("LeadRow:", id);
    setActiveRowIdInLead(id);
  };

  const handlePricingRowClick = (id) => {
    console.log("PricingRow:", id);
    setActiveRowIdInPricing(id);
  };

  const formatDateToISO = (dateStr) => {
    const [day, month, year] = dateStr.split("/").map(Number);
    return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(
      2,
      "0"
    )}`;
  };

  const handleMatchButtonClick = () => {
    if (activeRowIdInLead !== null && activeRowIdInPricing !== null) {
      const selectedPricing = rawPricings.find(
        (pricing) => pricing._id === activeRowIdInPricing
      );

      const showName = selectedPricing.showName;

      const [show, nextDates, endDate] = parseShowName(showName);

      const duration = calculateDuration(nextDates, endDate);
      const formattedNextDates = formatDateToISO(nextDates);
      const formattedEndDate = formatDateToISO(endDate);

      setFormDataLocator({
        ...formDataLocator,
        Show: showName,
        "Next Dates": formattedNextDates,
        "End Date": formattedEndDate,
        Duration: duration,
      });

      setIsPopupVisible(true);
    }
  };

  const parseShowName = (showName) => {
    // Split the string by commas
    const parts = showName.split(",").map((part) => part.trim());

    // Extract the relevant parts
    const show = parts[0] || "";
    const nextDates = parts[1] || "";
    const endDate = parts[2] || "";

    return [show, nextDates, endDate];
  };

  const convertToMMDDYYYY = (dateStr) => {
    // Split the date string into day, month, and year
    const [day, month, year] = dateStr.split("/").map(Number);

    // Return the date in MM/DD/YYYY format
    return `${month}/${day}/${year}`;
  };

  const calculateDuration = (startDateStr, endDateStr) => {
    // Convert date strings from dd/mm/yyyy to mm/dd/yyyy
    const startDate = new Date(convertToMMDDYYYY(startDateStr));
    const endDate = new Date(convertToMMDDYYYY(endDateStr));

    // Calculate the duration in days
    const durationInDays = Math.ceil(
      (endDate - startDate) / (1000 * 60 * 60 * 24)
    );

    return durationInDays;
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  const handleChangedata = (e) => {
    const { id, name, value } = e.target;
    setFormDataLocator((prevData) => ({
      ...prevData,
      [id]: value,
      [name]: value,
    }));
  };

  const handleButtonClick = async (e) => {
    e.preventDefault();
    handleClosePopup();

    // Add request to queue and handle it
    try {
      await addRequestToQueue(
        formDataLocator,
        activeRowIdInLead,
        activeRowIdInPricing
      );
      // Reset form data after processing
      setFormDataLocator({
        edition: "",
        Show: "",
        "Show Url": "",
        "Next Dates": "",
        "End Date": "",
        Duration: "",
        "Convention Center / Host Hotel": "",
        Venue: "",
        "City, Country": "",
      });
    } catch (error) {
      console.error("Error processing request:", error);
    }
  };
  console.log(processStatuses);

  return (
    <div className="pricing-manager-container">
      <div className="main-header-info">
        <h2 className="main-header-h2">Locator View</h2>
      </div>
      <div className="main-pricing-contain">
        <div className="sidebar-container">
          {isOpen ? (
            <div className="menu-bar-open">
              <MdOutlineClose className="menu-icon" onClick={toggleSidebar} />
              <button onClick={showLocatorTable} className="sidebar-btn">
                Locator Table
              </button>
              <button onClick={showMatchTable} className="sidebar-btn">
                Match pricing show
              </button>
              <button onClick={showUploadControllers} className="sidebar-btn">
                Upload Shows
              </button>
            </div>
          ) : (
            <div className="menu-bar-close">
              <HiOutlineMenuAlt2
                className="menu-icon"
                onClick={toggleSidebar}
              />
            </div>
          )}
        </div>
        {isMatchTableActive && (
          <div className="request-status-container">
            {processStatuses.map((req) => (
              <div key={req.id} className={`request-status ${req.status}`}>
                <p>Show: {req.show}</p>
                <p>Status: {req.status}</p>
              </div>
            ))}
          </div>
        )}

        {isLocatorTableActive && (
          <div className="table-container">
            <h2 className="table-title">Locator Table</h2>
            <LocatorTable
              data={locatorData}
              fetchData={fetchLocatorData}
              role={role}
            />
          </div>
        )}
        {isUploadActive && (
          <div className="table-container">
            <h2 className="table-title">Upload Data</h2>
            <UploadLocatorsSection />
          </div>
        )}

        {isMatchTableActive && (
          <div className="match-locator-container">
            <h2 className="match-locator-header">Match Data</h2>
            <div className="match-tables-contain">
              <div className="match-btn-container">
                <button
                  className="match-button"
                  onClick={handleMatchButtonClick}
                >
                  Match
                </button>
              </div>
              <div className="match-tables-section">
                <MatchingTable
                  rawLeads={rawLeads}
                  rawPricings={rawPricings}
                  fetchRawLeads={fetchRawLeads}
                  fetchRawPricings={fetchRawPricings}
                  activeRowIdInLead={activeRowIdInLead}
                  activeRowIdInPricing={activeRowIdInPricing}
                  handleLeadRowClick={handleLeadRowClick}
                  handlePricingRowClick={handlePricingRowClick}
                />
              </div>
            </div>
          </div>
        )}

        {isPopupVisible && (
          <LocatorPopup
            handleClosePopup={handleClosePopup}
            formDataLocator={formDataLocator}
            handleChangedata={handleChangedata}
            handleButtonClick={handleButtonClick}
          />
        )}
      </div>
    </div>
  );
};

export default Locator;
