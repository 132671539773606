import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Box,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { format } from "date-fns";
import { AuthContext } from "../context/AuthContext";
import { baseUrl } from "../../api/Api";

const ShowLeads = () => {
  const [leads, setLeads] = useState([]);
  const [statusCounts, setStatusCounts] = useState({});
  const [showName, setShowName] = useState("");
  const [locatorId, setLocatorId] = useState("");
  const [openDropdown, setOpenDropdown] = useState(null);
  const [selectedShowId, setSelectedShowId] = useState("");
  const [shows, setShows] = useState([]);
  const { token } = useContext(AuthContext);

  const location = useLocation();

  useEffect(() => {
    const fetchShows = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/locator/get-all-shows`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the bearer token in the request
            },
          }
        );
        setShows(response.data); // Store the fetched shows in state
      } catch (error) {
        console.error("Error fetching shows:", error);
      }
    };

    fetchShows();
  }, [token]);

  useEffect(() => {
    if (location.state && location.state.showLeads) {
      setLeads(location.state.showLeads);
      setShowName(location.state.show);
      setLocatorId(location.state.showId);

      // Calculate status counts
      const counts = location.state.showLeads.reduce((acc, lead) => {
        acc[lead.Status] = (acc[lead.Status] || 0) + 1;
        return acc;
      }, {});
      setStatusCounts(counts);
    }
  }, [location.state]);
  console.log(showName);

  const formatDate = (dateString) => {
    return format(new Date(dateString), "MMM dd, yyyy h:mm a");
  };

  const handleShowClick = (leadId) => {
    setOpenDropdown((prev) => (prev === leadId ? null : leadId)); // Toggle dropdown visibility
  };

  const handleDropdownChange = async (event, lead) => {
    const newShowId = event.target.value;
    setSelectedShowId(newShowId);

    // Make the PUT request to assign the lead to a new show
    try {
      const response = await axios.put(
        `${baseUrl}/api/locator/assign-lead-to-show`,
        {
          leadId:lead._id,
          oldShowId: lead.showId,
          newShowId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // Remove the lead from the state after successful update
        console.log(lead);

        setLeads((prevLeads) => prevLeads.filter((l) => l._id !== lead._id));
        console.log("Updated leads after removal:", leads);
        setOpenDropdown(null);
      }
    } catch (error) {
      console.error("Error assigning lead to a new show:", error);
    }
  };

  return (
    <>
      <Box mb={2}>
        <Grid container spacing={2}>
          {/* Status counts */}
          {statusCounts &&
            Object.entries(statusCounts).map(([status, count]) => (
              <Grid item xs={6} sm={3} md={2} key={status}>
                <Box
                  sx={{
                    padding: 1,
                    textAlign: "center",
                    border: "1px solid #e0e0e0",
                    borderRadius: 1,
                  }}
                >
                  <Typography variant="subtitle1">{status}</Typography>
                  <Typography variant="h6">{count}</Typography>
                </Box>
              </Grid>
            ))}
        </Grid>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Show</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Lead</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Phone</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Email</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Timezone</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Status</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Closer Note</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Closing Date</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Closer Name</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Hidden</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leads.map((lead) => (
              <TableRow key={lead._id}>
                <TableCell
                  onClick={() => handleShowClick(lead._id)}
                  style={{ cursor: "pointer" }}
                >
                  {openDropdown === lead._id ? (
                    <FormControl variant="outlined" size="small">
                      <Select
                        value=""
                        onChange={(event) => handleDropdownChange(event, lead)}
                        displayEmpty
                        sx={{ minWidth: 120 }}
                      >
                        <MenuItem value="" disabled>
                          Select Show
                        </MenuItem>
                        {shows.map((show) => (
                          <MenuItem key={show._id} value={show._id}>
                            {show.Show}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    showName
                  )}
                </TableCell>
                <TableCell>{lead.Lead}</TableCell>
                <TableCell>{lead.Phone}</TableCell>
                <TableCell>{lead.Email}</TableCell>
                <TableCell>{lead.Timezone}</TableCell>
                <TableCell>{lead.Status}</TableCell>
                <TableCell>{lead["Closer Note"]}</TableCell>
                <TableCell>{lead["Closing Date"]}</TableCell>
                <TableCell>{lead["Closer Assigned"]?.closerName}</TableCell>
                <TableCell>{lead.hidden ? "Yes" : "No"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ShowLeads;
