import React from "react";
import ResDrop from "./ResDrop";

const FiltersSection = ({
  handleChangeFilter,
  companyOptions,
  selectedCompany,
  statusOptions,
  selectedStatus,
  selectedShowName,
  showNameOptions,
  timeZoneOptions,
  selectedTimeZoneFilter,
}) => {

  return (
    <div className="main-header-info filters-section-container">
      <ResDrop
        label="TimeZone"
        options={timeZoneOptions}
        selectedValue={selectedTimeZoneFilter}
        handleChange={handleChangeFilter}
        name="timeZone"
      />
      <ResDrop
        label="ShowName"
        options={showNameOptions}
        selectedValue={selectedShowName}
        handleChange={handleChangeFilter}
        name="showName"
      />
      <ResDrop
        label="Status"
        options={statusOptions}
        selectedValue={selectedStatus}
        handleChange={handleChangeFilter}
        name="status"
      />
      <ResDrop
        label="Company"
        options={companyOptions}
        selectedValue={selectedCompany}
        handleChange={handleChangeFilter}
        name="companies"
      />
    </div>
  );
};

export default FiltersSection;